import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  Paper,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Navigation items with their corresponding section IDs
const navItems = [
  { title: 'Introduction', id: 'intro' },
  { title: 'What Are Cookies', id: 'what-are-cookies' },
  { title: 'Essential Cookies', id: 'essential' },
  { title: 'Performance Cookies', id: 'performance' },
  { title: 'Functional Cookies', id: 'functional' },
  { title: 'Third-Party Cookies', id: 'third-party' },
  { title: 'Your Choices', id: 'choices' },
  { title: 'Cookie Management', id: 'management' },
  { title: 'Data Retention', id: 'retention' },
  { title: 'Policy Updates', id: 'updates' },
  { title: 'Contact Us', id: 'contact' }
];

function CookieSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('intro');

  // Add intersection observer to update active section while scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-50px 0px -50px 0px',
        threshold: 0.2
      }
    );

    // Observe all sections
    navItems.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    return () => {
      navItems.forEach((item) => {
        const element = document.getElementById(item.id);
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      setActiveSection(sectionId);
      if (isMobile) {
        setMobileOpen(false);
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const SideNav = () => (
    <Box
      sx={{
        width: '100%',
        maxWidth: { md: '280px' },
        bgcolor: 'background.paper',
        p: 3,
      }}
    >
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
        Contents
      </Typography>
      <List sx={{ position: 'sticky', top: 24 }}>
        {navItems.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() => scrollToSection(item.id)}
            sx={{
              borderRadius: 1,
              mb: 0.5,
              bgcolor: activeSection === item.id ? 'primary.light' : 'transparent',
              color: (theme) => activeSection === item.id 
                ? theme.palette.primary.main 
                : theme.palette.text.disabled,
              '&:hover': {
                bgcolor: 'primary.lighter',
              },
            }}
          >
            <ListItemText 
              primary={item.title}
              primaryTypographyProps={{
                fontSize: '0.95rem',
                fontWeight: activeSection === item.id ? 600 : 400,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: 'background.default',
      pt: { xs: 8, sm: 10 },
      pb: { xs: 6, sm: 8 },
    }}>
      {/* Mobile menu button */}
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ 
            position: 'fixed',
            left: 16,
            top: 16,
            zIndex: 1200,
            bgcolor: 'background.paper',
            boxShadow: 2,
            '&:hover': { bgcolor: 'background.paper' },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Mobile drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '280px',
            boxSizing: 'border-box',
          },
        }}
      >
        <SideNav />
      </Drawer>

      <Container maxWidth="xl">
        <Box sx={{ 
          display: 'flex',
          gap: { md: 6, lg: 8 },
        }}>
          {/* Desktop side navigation */}
          <Box sx={{ 
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
          }}>
            <Paper
              elevation={2}
              sx={{
                position: 'sticky',
                top: 24,
                width: '280px',
                borderRadius: 2,
              }}
            >
              <SideNav />
            </Paper>
          </Box>

          {/* Main content */}
          <Box sx={{ flex: 1 }}>
            <Paper
              elevation={2}
              sx={{
                p: { xs: 3, sm: 4, md: 5 },
                borderRadius: 2,
              }}
            >
              {/* Main content sections */}
              <section id="intro">
                <Typography variant="h4" gutterBottom>
                  Cookie Policy
                </Typography>
                <Typography variant="body1" paragraph>
                  Last updated: March 14, 2024
                </Typography>
                <Typography variant="body1" paragraph>
                  This Cookie Policy explains how MyDailyMath uses cookies and similar technologies to provide, customize, 
                  and improve your educational experience on our platform.
                </Typography>
              </section>

              <section id="what-are-cookies">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  What Are Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                  Cookies are small text files placed on your device when you visit our website. They serve different purposes:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Remember your preferences and settings</li>
                  <li>Keep you signed in to your account</li>
                  <li>Help us understand how you use our site</li>
                  <li>Improve our educational services</li>
                </Typography>
                <Typography variant="body1" paragraph>
                  We use both session cookies (temporary) and persistent cookies (remain until expiration or deletion).
                </Typography>
              </section>

              <section id="essential">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Essential Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                  Required for core website functionality:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Authentication and security</li>
                  <li>Session management</li>
                  <li>Form submissions</li>
                  <li>User preferences</li>
                </Typography>
              </section>

              <section id="performance">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Performance Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                  Help us improve our services:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Usage analytics</li>
                  <li>Error detection</li>
                  <li>Performance monitoring</li>
                  <li>Traffic analysis</li>
                </Typography>
              </section>

              <section id="functional">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Functional Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                  Enhance your experience:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Learning progress tracking</li>
                  <li>Personalization settings</li>
                  <li>Content preferences</li>
                  <li>Interactive features</li>
                </Typography>
              </section>

              <section id="third-party">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Third-Party Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                  Set by external services we use:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Analytics (Google Analytics)</li>
                  <li>Educational content (Video platforms)</li>
                  <li>Content delivery networks</li>
                  <li>Security services</li>
                </Typography>
              </section>

              <section id="choices">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Your Cookie Choices
                </Typography>
                <Typography variant="body1" paragraph>
                  You have control over how cookies are used:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Accept or decline non-essential cookies</li>
                  <li>Change preferences at any time</li>
                  <li>Delete cookies through your browser</li>
                  <li>Use private browsing mode</li>
                </Typography>
                <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                  Note: Blocking essential cookies may affect basic website functionality.
                </Typography>
              </section>

              <section id="updates">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Policy Updates
                </Typography>
                <Typography variant="body1" paragraph>
                  We may update this Cookie Policy to reflect:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Changes in our practices</li>
                  <li>New regulatory requirements</li>
                  <li>Technology improvements</li>
                  <li>User feedback and suggestions</li>
                </Typography>
                <Typography variant="body1" paragraph>
                  We'll notify you of significant changes through our website or email.
                </Typography>
              </section>

              <section id="management">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Cookie Management
                </Typography>
                <Typography variant="body1" paragraph>
                  Control your cookie preferences through:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Browser settings
                    <Typography variant="body2" sx={{ pl: 2 }}>
                      Chrome: Settings → Privacy and Cookies
                      Firefox: Options → Privacy Settings
                      Safari: Preferences → Privacy Settings
                      Edge: Settings → Privacy and Security
                    </Typography>
                  </li>
                  <li>Our cookie consent tool</li>
                  <li>Browser extensions</li>
                </Typography>
              </section>

              <section id="retention">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Data Retention
                </Typography>
                <Typography variant="body1" paragraph>
                  Cookie duration varies by type:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Session cookies: Deleted when browser closes</li>
                  <li>Persistent cookies: Up to 12 months</li>
                  <li>Essential cookies: 24 hours maximum</li>
                  <li>Preference cookies: Up to 1 year</li>
                </Typography>
              </section>

              <section id="contact">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                  Questions about cookies? Contact us:
                </Typography>
                <Typography variant="body1">
                  ALFEROV EDUCATIONAL INSTITUTE
                </Typography>
                <Typography variant="body1">
                  13525 INDIAN TRAIL ROAD
                </Typography>
                <Typography variant="body1">
                  LOS GATOS, CA 95033
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Email: privacy@mydailymath.com
                </Typography>
              </section>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default CookieSection;