import React from 'react';
import { Box, Typography, Link, Grid, Divider } from '@mui/material';
import { FaPatreon, FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <Box component="footer" sx={{ backgroundColor: theme.palette.grey[900], color: theme.palette.common.white, py: 8 }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', px: 4 }}>
        <Grid container spacing={4} textAlign={{ xs: 'center', md: 'left' }}>
          
          {/* Column 1: Main Navigation */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              MyDailyMath
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>
                <Link href="/support-project" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Support the Project
                </Link>
              </li>
              <li>
                <Link href="/hire-tutor" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Hire Tutor
                </Link>
              </li>
              <li>
                <Link href="/Textbooks" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Textbooks
                </Link>
              </li>
              <li>
                <Link href="/roadmap" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Our Roadmap
                </Link>
              </li>
              <li>
                <Link href="/about" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  About the Project
                </Link>
              </li>
            </Box>
          </Grid>

          {/* Column 2: Legal and Miscellaneous Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              Legal
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>
                <Link href="/tos" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link href="/privacy" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link href="/cookies" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Cookie Policy
                </Link>
              </li>
            </Box>
          </Grid>

          {/* Column 3: Contact Information */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              Contact
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>13525 INDIAN TRAIL ROAD</li>
              <li>LOS GATOS, CA 95033</li>
              <li>
                Email: <Link href="mailto:info@mydailymath.com" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  info@mydailymath.com
                </Link>
              </li>
            </Box>
          </Grid>
        </Grid>

        {/* Full-Width Non-Profit Statement */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Typography variant="body2">
            MyDailyMath is a project of ALFEROV EDUCATIONAL INSTITUTE. 
            A registered 501(c)(3) non-profit organization. 
            All cash donations are tax-deductible.
          </Typography>
          <Typography variant="body2" mt={2}>
            &copy; {new Date().getFullYear()} ALFEROV EDUCATIONAL INSTITUTE
          </Typography>
        </Box>

        {/* Full-Width Social Media Section */}
        <Divider sx={{ backgroundColor: theme.palette.grey[700], mt: 8, mb: 4 }} />
        <Box textAlign="center">
          <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
            Follow us:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: theme.spacing(3) }}>
            <Link href="https://www.patreon.com/MyDailyMath" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaPatreon />
            </Link>
            <Link href="https://www.tiktok.com/@mydailymath48" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaTiktok />
            </Link>
            <Link href="https://instagram.com/my_daily_math" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaInstagram />
            </Link>
            <Link href="https://www.youtube.com/@MyDailyMathCom?sub_confirmation=1" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaYoutube />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
