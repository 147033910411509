import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';
import HireTutor from './pages/HireTutor';
import Textbook from './pages/Textbooks';
import About from './pages/About';
import Roadmap from './pages/Roadmap';
import SupportProject from './pages/SupportProject';
import NotFound from './pages/NotFound';
import LinkTree from './pages/LinkTree';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import TermsOfServicePage from './pages/ToS';
import CookiePolicyPage from './pages/CookiesPolicy';
import CookieBanner from './components/CookieBanner';
import DonationForm from './components/DonationForm';
import SuccessPage from './pages/SuccessPage';
import CanceledPage from './pages/CanceledPage';
import VideosPage from './pages/VideosPage';

function App() {
  const hostname = window.location.hostname;
  const isLinkTree = hostname.startsWith('linktree.');

  if (isLinkTree) {
    return <LinkTree />;
  }
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/hire-tutor" element={<HireTutor />} />
            <Route path="/textbooks" element={<Textbook />} />
            <Route path="/about" element={<About />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/support-project" element={<SupportProject />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/tos" element={<TermsOfServicePage />} />
            <Route path="/cookies" element={<CookiePolicyPage />} />
            <Route path="/donation" element={<DonationForm />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/canceled" element={<CanceledPage />} />
            <Route path="/videos" element={<VideosPage />} />
            <Route path="*" element={<NotFound />} />
            
            {/* Add more routes here as needed */}
          </Routes>
        </main>
        <Footer />
        <CookieBanner />
      </div>
    </BrowserRouter>
  );
}

export default App;