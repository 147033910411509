import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Container, Paper, Divider, IconButton } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import YouTube from 'react-youtube';
import { useTheme } from '@mui/material/styles';

const YOUTUBE_API_KEY = 'AIzaSyD6sLyZa8kyWBVO6catolC8FhbdRTUT_yk';
const CHANNEL_ID = 'UC_pucTnb2aNyUBvcjYADGzA';

function VideoGallery() {
  const theme = useTheme();
  const [playlists, setPlaylists] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [playlistVideos, setPlaylistVideos] = useState({});
  const [displayCount, setDisplayCount] = useState(4);
  const [startIndex, setStartIndex] = useState(0);

  // Fetch playlists from channel
  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&maxResults=50&key=${YOUTUBE_API_KEY}`
        );
        const data = await response.json();
        setPlaylists(data.items);
        // Fetch videos for all playlists immediately
        data.items.forEach(playlist => {
          fetchPlaylistVideos(playlist.id);
        });
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };
    fetchPlaylists();
  }, []);

  // Fetch videos for a playlist
  const fetchPlaylistVideos = async (playlistId) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${YOUTUBE_API_KEY}`
      );
      const data = await response.json();
      setPlaylistVideos(prev => ({
        ...prev,
        [playlistId]: data.items
      }));
      // Set initial video if not set
      if (!currentVideo && data.items.length > 0) {
        setCurrentVideo(data.items[0].snippet.resourceId.videoId);
        setCurrentPlaylist(playlistId);
      }
    } catch (error) {
      console.error('Error fetching playlist videos:', error);
    }
  };

  const handleVideoEnd = () => {
    const currentPlaylistVideos = playlistVideos[currentPlaylist];
    if (!currentPlaylistVideos) return;

    const currentIndex = currentPlaylistVideos.findIndex(
      video => video.snippet.resourceId.videoId === currentVideo
    );
    
    if (currentIndex < currentPlaylistVideos.length - 1) {
      setCurrentVideo(currentPlaylistVideos[currentIndex + 1].snippet.resourceId.videoId);
    }
  };

  const handleThumbnailClick = (videoId, playlistId) => {
    setCurrentVideo(videoId);
    setCurrentPlaylist(playlistId);
  };

  // Update display count based on screen width
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 600) setDisplayCount(1);      // xs
      else if (width < 960) setDisplayCount(2); // sm
      else if (width < 1280) setDisplayCount(3);// md
      else setDisplayCount(4);                  // lg and up
    };

    handleResize(); // Initial call
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = (playlistId) => {
    const maxStartIndex = Math.max(0, (playlistVideos[playlistId]?.length || 0) - displayCount);
    setStartIndex(prev => Math.min(prev + displayCount, maxStartIndex));
  };

  const handlePrev = () => {
    setStartIndex(prev => Math.max(0, prev - displayCount));
  };

  // Sort playlists by video count
  const sortedPlaylists = [...playlists].sort((a, b) => {
    const aCount = playlistVideos[a.id]?.length || 0;
    const bCount = playlistVideos[b.id]?.length || 0;
    return bCount - aCount; // Sort in descending order
  });

  return (
    <Container maxWidth="lg">
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          mb: 4
        }}
      >
        {sortedPlaylists.map((playlist) => (
          <Box 
            key={playlist.id} 
            sx={{ 
              mb: 12, // Increased spacing between sections
              '&:last-child': { mb: 0 }
            }}
          >
            {/* Section Title with full-width underline */}
            <Box sx={{ mb: 4, position: 'relative' }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  mb: 2,
                  fontWeight: 'bold',
                  color: theme.palette.primary.main
                }}
              >
                {playlist.snippet.title}
              </Typography>
              <Divider 
                sx={{ 
                  height: 3,
                  backgroundColor: theme.palette.primary.main,
                  opacity: 0.7
                }} 
              />
            </Box>
            
            {/* Main Video Player - Fixed 16:9 ratio */}
            <Box sx={{ 
              mb: 4,
              mx: 'auto',
              position: 'relative',
              width: '100%', // Width is 90% of container
              maxWidth: '1800px',
              paddingTop: '56.25%', // Always use 56.25% for 16:9 ratio (9/16 = 0.5625)
              backgroundColor: 'black',
              borderRadius: 9,
              overflow: 'hidden',
              boxShadow: theme.shadows[4]
            }}>
              <YouTube
                videoId={currentVideo || (playlistVideos[playlist.id]?.[0]?.snippet.resourceId.videoId)}
                opts={{
                  height: '100%',
                  width: '100%',
                  playerVars: {
                    autoplay: currentPlaylist === playlist.id ? 1 : 0,
                    playlist: playlistVideos[playlist.id]
                      ?.map(video => video.snippet.resourceId.videoId)
                      .join(','),
                  },
                }}
                onEnd={handleVideoEnd}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>

            {/* Thumbnails Slider */}
            <Box sx={{ position: 'relative', px: 5, mt: 4 , pt: 4}}>
              {/* Navigation Buttons */}
              {startIndex > 0 && (
                <IconButton 
                  onClick={handlePrev}
                  sx={{ 
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
                  }}
                >
                  <FaChevronLeft />
                </IconButton>
              )}
              
              {playlistVideos[playlist.id] && startIndex < playlistVideos[playlist.id].length - displayCount && (
                <IconButton 
                  onClick={() => handleNext(playlist.id)}
                  sx={{ 
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
                  }}
                >
                  <FaChevronRight />
                </IconButton>
              )}

              {/* Thumbnails Grid */}
              <Grid container spacing={2}>
                {playlistVideos[playlist.id]
                  ?.slice(startIndex, startIndex + displayCount)
                  .map((video) => (
                    <Grid item xs={12} sm={6} md={12/displayCount} key={video.id}>
                      <Paper 
                        elevation={2}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { 
                            transform: 'translateY(-4px)',
                            transition: 'transform 0.2s ease-in-out',
                          },
                          borderRadius: 1,
                          overflow: 'hidden'
                        }}
                        onClick={() => handleThumbnailClick(video.snippet.resourceId.videoId, playlist.id)}
                      >
                        <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                          <img
                            src={video.snippet.thumbnails.medium.url}
                            alt={video.snippet.title}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              opacity: currentVideo === video.snippet.resourceId.videoId ? 0.6 : 1,
                            }}
                          />
                        </Box>
                        <Box sx={{ p: 1.5 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              lineHeight: 1.3,
                              fontWeight: 'medium'
                            }}
                          >
                            {video.snippet.title}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        ))}
      </Paper>
    </Container>
  );
}

export default VideoGallery;
