import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  Paper,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
  Link
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Navigation items with their corresponding section IDs
const navItems = [
  { title: 'Introduction', id: 'intro' },
  { title: 'Information Collection', id: 'info-collect' },
  { title: 'Use of Information', id: 'info-use' },
  { title: 'Cookie Policy', id: 'cookies' },
  { title: 'Data Security', id: 'security' },
  { title: 'Student Privacy', id: 'student-data' },
  { title: 'Educational Content', id: 'edu-content' },
  { title: 'Third-Party Services', id: 'third-party' },
  { title: 'Your Rights & Choices', id: 'rights' },
  { title: 'International Users', id: 'international' },
  { title: 'COPPA Compliance', id: 'coppa' },
  { title: 'FERPA Compliance', id: 'ferpa' },
  { title: 'Accessibility', id: 'accessibility' },
  { title: 'Updates to Policy', id: 'updates' },
  { title: 'Contact Us', id: 'contact' }
];

function PrivacyPolicySection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('intro');

  // Add intersection observer to update active section while scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-50px 0px -50px 0px',
        threshold: 0.2
      }
    );

    // Observe all sections
    navItems.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    return () => {
      navItems.forEach((item) => {
        const element = document.getElementById(item.id);
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  console.log('Theme colors:', theme.palette);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Smooth scroll to section with offset for header
      const offset = 80; // Adjust based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      setActiveSection(sectionId);
      if (isMobile) {
        setMobileOpen(false);
      }
    }
  };

  const SideNav = () => (
    <Box
      sx={{
        width: '100%',
        maxWidth: { md: '280px' },
        bgcolor: 'background.paper',
        p: 3,
      }}
    >
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
        Contents
      </Typography>
      <List sx={{ position: 'sticky', top: 24 }}>
        {navItems.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() => scrollToSection(item.id)}
            sx={{
              borderRadius: 1,
              mb: 0.5,
              bgcolor: activeSection === item.id ? 'primary.light' : 'transparent',
              color: (theme) => activeSection === item.id 
                ? theme.palette.primary.main 
                : theme.palette.grey[300],
              '&:hover': {
                bgcolor: 'primary.lighter',
              },
            }}
          >
            <ListItemText 
              primary={item.title}
              primaryTypographyProps={{
                fontSize: '0.95rem',
                fontWeight: activeSection === item.id ? 600 : 400,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: 'background.default',
      pt: { xs: 8, sm: 10 },
      pb: { xs: 6, sm: 8 },
    }}>
      {/* Mobile menu button */}
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ 
            position: 'fixed',
            left: 16,
            top: 16,
            zIndex: 1200,
            bgcolor: 'background.paper',
            boxShadow: 2,
            '&:hover': { bgcolor: 'background.paper' },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Mobile drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '280px',
            boxSizing: 'border-box',
          },
        }}
      >
        <SideNav />
      </Drawer>

      <Container maxWidth="xl">
        <Box sx={{ 
          display: 'flex',
          gap: { md: 6, lg: 8 },
        }}>
          {/* Desktop side navigation */}
          <Box sx={{ 
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
          }}>
            <Paper
              elevation={2}
              sx={{
                position: 'sticky',
                top: 24,
                width: '280px',
                borderRadius: 2,
              }}
            >
              <SideNav />
            </Paper>
          </Box>

          {/* Main content */}
          <Box sx={{ flex: 1 }}>
            <Paper
              elevation={2}
              sx={{
                p: { xs: 3, sm: 4, md: 5 },
                borderRadius: 2,
              }}
            >
              {/* Main content sections */}
              <section id="intro">
                <Typography variant="h4" gutterBottom>
                  Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                  Last updated: January 20, 2025
                </Typography>
                <Typography variant="body1" paragraph>
                  Welcome to MyDailyMath. This Privacy Policy explains how ALFEROV EDUCATIONAL INSTITUTE ("we", "us", or "our"), 
                  a 501(c)(3) non-profit organization, handles your information when you use mydailymath.com and our educational services.
                </Typography>
              </section>

              <section id="info-collect">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Information Collection
                </Typography>
                <Typography variant="body1" paragraph>
                  To provide and improve our educational services, we collect:
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Essential Information
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Account information (name, email, educational role)</li>
                  <li>Learning preferences and progress data</li>
                  <li>Educational background and goals</li>
                  <li>Communication preferences</li>
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Technical Data
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Device and browser information</li>
                  <li>Usage statistics and interaction data</li>
                  <li>IP address and location data</li>
                  <li>Performance and error data</li>
                </Typography>
              </section>

              <section id="info-use">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Use of Information
                </Typography>
                <Typography variant="body1" paragraph>
                  We use your information to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Deliver personalized learning experiences</li>
                  <li>Improve our educational content and services</li>
                  <li>Ensure platform security and performance</li>
                  <li>Communicate important updates and information</li>
                  <li>Comply with legal and regulatory requirements</li>
                </Typography>
              </section>

              <section id="student-data">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Student Privacy
                </Typography>
                <Typography variant="body1" paragraph>
                  We maintain strict standards for student data protection:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>No sale or unauthorized sharing of student data</li>
                  <li>Parental consent required for users under 13</li>
                  <li>Limited data collection from minors</li>
                  <li>Compliance with FERPA and COPPA regulations</li>
                  <li>Regular deletion of inactive student accounts</li>
                </Typography>
              </section>

              <section id="security">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                  We protect your information through:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>End-to-end encryption for sensitive data</li>
                  <li>Regular security audits and monitoring</li>
                  <li>Strict access controls and authentication</li>
                  <li>Secure backup and recovery procedures</li>
                  <li>Employee data protection training</li>
                </Typography>
              </section>

              <section id="cookies">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Cookie Usage
                </Typography>
                <Typography variant="body1" paragraph>
                  We use cookies to improve your experience:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Essential cookies for basic functionality</li>
                  <li>Performance cookies for service improvement</li>
                  <li>Preference cookies for customization</li>
                  <li>Analytics cookies for usage understanding</li>
                </Typography>
                <Typography variant="body1" paragraph>
                  You can manage cookie preferences through your browser settings.
                </Typography>
              </section>

              <section id="rights">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Your Privacy Rights
                </Typography>
                <Typography variant="body1" paragraph>
                  You have the right to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Access your personal information</li>
                  <li>Request data correction or deletion</li>
                  <li>Opt-out of certain data collection</li>
                  <li>Export your data in a portable format</li>
                  <li>File a privacy complaint</li>
                </Typography>
              </section>

              <section id="updates">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Policy Updates
                </Typography>
                <Typography variant="body1" paragraph>
                  We may update this policy to reflect changes in our practices or legal requirements. Material changes will be communicated through our website or email notification.
                </Typography>
              </section>

              <section id="contact">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                  For privacy-related inquiries:
                </Typography>
                <Typography variant="body1">
                  ALFEROV EDUCATIONAL INSTITUTE
                </Typography>
                <Typography variant="body1">
                  13525 INDIAN TRAIL ROAD
                </Typography>
                <Typography variant="body1">
                  LOS GATOS, CA 95033
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Email: privacy@mydailymath.com
                </Typography>
              </section>

              <section id="third-party">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Third-Party Services
                </Typography>
                <Typography variant="body1" paragraph>
                  We work with trusted third-party services to provide our educational platform:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Learning management systems</li>
                  <li>Payment processors for donations</li>
                  <li>Analytics providers</li>
                  <li>Content delivery networks</li>
                  <li>Email service providers</li>
                </Typography>
                <Typography variant="body1" paragraph>
                  All third-party providers are carefully selected and required to maintain appropriate security measures.
                </Typography>
              </section>

              <section id="international">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  International Users
                </Typography>
                <Typography variant="body1" paragraph>
                  Our services are operated in the United States. If you are accessing our services from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located.
                </Typography>
              </section>

              <section id="edu-content">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Educational Content
                </Typography>
                <Typography variant="body1" paragraph>
                  As an educational platform, we handle content and data related to learning:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Educational materials and resources are provided for personal learning use only</li>
                  <li>Student progress and performance data is collected to enhance learning experience</li>
                  <li>Teachers and educators may have access to aggregated, anonymized data for educational purposes</li>
                  <li>Content usage patterns are analyzed to improve our educational offerings</li>
                </Typography>
              </section>

              <section id="donations">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Donation Information
                </Typography>
                <Typography variant="body1" paragraph>
                  As a 501(c)(3) non-profit organization, we collect and process donation information with utmost care:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Financial information is processed securely through trusted payment processors</li>
                  <li>Donation records are maintained for tax and regulatory compliance</li>
                  <li>Donor information is kept confidential unless permission is granted for recognition</li>
                  <li>Tax receipts and acknowledgments are provided as required by law</li>
                </Typography>
              </section>

              <section id="coppa">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  COPPA Compliance
                </Typography>
                <Typography variant="body1" paragraph>
                  We comply with the Children's Online Privacy Protection Act (COPPA). This federal law provides important privacy protections for children under 13 years of age.
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Parental Consent Requirements
                </Typography>
                <Typography variant="body1" paragraph>
                  Before collecting any personal information from children under 13, we require verifiable parental consent. Parents will:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Receive notification about our information practices</li>
                  <li>Have the opportunity to review and approve data collection</li>
                  <li>Be able to consent to the collection and use of their child's information</li>
                  <li>Have the ability to revoke consent at any time</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Limited Data Collection
                </Typography>
                <Typography variant="body1" paragraph>
                  We strictly limit the personal information we collect from children to what is reasonably necessary to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Participate in our educational activities</li>
                  <li>Track educational progress and achievements</li>
                  <li>Maintain the security and functionality of our services</li>
                  <li>Respond to customer service inquiries</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Parental Rights and Controls
                </Typography>
                <Typography variant="body1" paragraph>
                  Parents or legal guardians of children under 13 have the right to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Review their child's personal information</li>
                  <li>Request deletion of their child's personal information</li>
                  <li>Refuse further collection or use of their child's information</li>
                  <li>Request changes to any stored information about their child</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Educational Context Protections
                </Typography>
                <Typography variant="body1" paragraph>
                  In educational contexts, we provide additional protections:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Use of information solely for educational purposes</li>
                  <li>Integration with school-based privacy policies and procedures</li>
                  <li>Compliance with school district data protection requirements</li>
                  <li>Regular audits of data collection and usage practices</li>
                </Typography>
              </section>

              <section id="ferpa">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  FERPA Compliance
                </Typography>
                <Typography variant="body1" paragraph>
                  The Family Educational Rights and Privacy Act (FERPA) is a federal law that protects the privacy of student education records. Our compliance with FERPA includes comprehensive protections for all educational data.
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Educational Records Protection
                </Typography>
                <Typography variant="body1" paragraph>
                  We maintain strict controls over educational records, including:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Secure storage and transmission of all educational data</li>
                  <li>Limited access to authorized personnel only</li>
                  <li>Regular security updates and monitoring</li>
                  <li>Encryption of sensitive educational information</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Access Rights
                </Typography>
                <Typography variant="body1" paragraph>
                  Under FERPA, parents and eligible students have specific rights:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Right to inspect and review educational records</li>
                  <li>Right to request corrections to inaccurate information</li>
                  <li>Right to have some control over information disclosure</li>
                  <li>Right to file complaints about FERPA violations</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  Information Sharing Restrictions
                </Typography>
                <Typography variant="body1" paragraph>
                  We restrict the sharing of student information according to FERPA guidelines:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>No disclosure without written consent, except as permitted by law</li>
                  <li>Limited sharing with school officials with legitimate educational interests</li>
                  <li>Compliance with directory information restrictions</li>
                  <li>Maintenance of detailed records of information requests and disclosures</li>
                </Typography>
              </section>

              <section id="accessibility">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Accessibility & Equal Access
                </Typography>
                <Typography variant="body1" paragraph>
                  As a non-profit educational organization, we are committed to providing accessible services:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Compliance with Web Content Accessibility Guidelines (WCAG)</li>
                  <li>Accommodations for users with disabilities</li>
                  <li>Alternative formats for educational content when needed</li>
                  <li>Regular accessibility audits and improvements</li>
                </Typography>
              </section>

              <section id="nonprofit-status">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  Non-Profit Status & Data Use
                </Typography>
                <Typography variant="body1" paragraph>
                  As a 501(c)(3) non-profit organization:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Data is never sold or used for commercial purposes</li>
                  <li>Donation information is handled according to IRS requirements</li>
                  <li>Transparency in data usage for educational purposes</li>
                  <li>Regular reporting to maintain non-profit compliance</li>
                </Typography>
              </section>

              <section id="california-rights">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  California Privacy Rights
                </Typography>
                <Typography variant="body1" paragraph>
                  California residents have additional rights under the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA):
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Right to know what personal information is collected</li>
                  <li>Right to delete personal information</li>
                  <li>Right to opt-out of data sharing</li>
                  <li>Right to non-discrimination for exercising privacy rights</li>
                </Typography>
              </section>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default PrivacyPolicySection;
