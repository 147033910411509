export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51QhGvnGG7d9NhwjjjU6T2bezXM0pNSxTPznpRP2CTVDPTQbNqwOXT2nJh5o7NJtFeuDz5KjqVM67AlXyoBleYjue00g5IBXWdl';
export const STRIPE_PUBLISHABLE_KEY_TEST = 'pk_test_51QhGvt4ekobic2OmnFULFi1Klr8E0ymiBx5j0mwiSZqOkocVhAoCVUbHrCZM1Uh4BRCibqHRGju5RhZbgsQqZJsM00jGX00FLj';

export const PRODUCT_TYPES = {
  MONTHLY: 'monthly',
  ONE_TIME: 'one_time'
};

export const MONTHLY_PRICES = {
    '5': 'price_1Qjxi8GG7d9NhwjjNeoGUCKp',    // $5/month price ID
    '11': 'price_1QjxilGG7d9Nhwjjm5olyAeH',   // $11/month price ID
    '23': 'price_1QjxjbGG7d9NhwjjHDRjonLT',   // $23/month price ID
    '53': 'price_1QjxkNGG7d9NhwjjWlMUuVkm',   // $53/month price ID
    '101': 'price_1QjxktGG7d9Nhwjj9fsZCEF3',  // $101/month price ID
    '503': 'price_1QjxlMGG7d9Nhwjjbm5gxFMZ',  // $503/month price ID
    '1009': 'price_1QjxngGG7d9NhwjjArJ3uJBn', // $1009/month price ID
  };

export const ONE_TIME_PRICES = {
    '5': 'price_1QkB5aGG7d9NhwjjWHLu4Oc9',    // Replace these with actual
    '11': 'price_1QkB6WGG7d9NhwjjY9j1LA6y',   // Replace these with actual
    '23': 'price_1QkB76GG7d9Nhwjjpr6Vv8w5',   // Replace these with actual
    '53': 'price_1QkB7cGG7d9Nhwjjsu9dBzlo',   // Replace these with actual
    '101': 'price_1QkB88GG7d9NhwjjQEjdAR3T',  // Replace these with actual
    '503': 'price_1QkB8iGG7d9NhwjjG8VK4J4x',  // Replace these with actual
    '1009': 'price_1QkB9NGG7d9NhwjjbFkREgOM', // Replace these with actual
  };


export const MONTHLY_PRICES_TEST = {
    'base': 'price_1QkAtt4ekobic2OmB8ganrR5', // Your $1/month test price ID
    '5': 'price_1Qk9LI4ekobic2OmpLmnn7fR',    // $5/month price ID
    '11': 'price_1Qk9LI4ekobic2OmpLmnn7fR',   // $11/month price ID
    '23': 'price_1QjxjbGG7d9NhwjjHDRjonLT',   // $23/month price ID
    '53': 'price_1QjxkNGG7d9NhwjjWlMUuVkm',   // $53/month price ID
    '101': 'price_1QjxktGG7d9Nhwjj9fsZCEF3',  // $101/month price ID
    '503': 'price_1QjxlMGG7d9Nhwjjbm5gxFMZ',  // $503/month price ID
    '1009': 'price_1QjxngGG7d9NhwjjArJ3uJBn', // $1009/month price ID
  };


// One-time donation price IDs
export const ONE_TIME_PRICES_TEST = {
    'base': 'price_1Qk9Uk4ekobic2OmbyTKdKUW', // Your $1/month test price ID
    '5': 'price_1Qk9Xx4ekobic2OmoixJaAEn',    // Replace these with actual
    '11': 'price_onetime_11',  // price IDs from your
    '23': 'price_onetime_23',  // Stripe Dashboard for
    '53': 'price_onetime_53',  // one-time payment prices
    '101': 'price_onetime_101',
    '503': 'price_onetime_503',
    '1009': 'price_onetime_1009',
  };

export const CUSTOM_PRODUCTS = {
  MONTHLY: {
    TEST: 'prod_custom_test',
    LIVE: 'prod_custom_live'
  },
  ONE_TIME: {
    TEST: 'price_1Qk9Uk4ekobic2OmbyTKdKUW',
    LIVE: 'prod_custom_onetime_live'
  }
};