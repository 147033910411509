import React from 'react';
import { Box, Container, Typography, Grid, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const MissionSectionWrapper = styled(Box)(({ theme }) => ({
  ...theme.custom.sectionPadding,
  backgroundImage: 'url(https://lawr.ru/files/01-obrazovatelnoe-pravo-jpg-1496782904-593718380dfcf.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.gradient,
    opacity: 0.9,
  }
}));

const FeatureCard = styled(motion(Card))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[10],
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '50%',
  background: theme.palette.secondaryGradient.gradient,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  '& svg': {
    fontSize: 40,
    color: theme.palette.common.white,
  }
}));

function MissionStatement() {
  const features = [
    {
      icon: <SchoolIcon />,
      title: "Proven Traditional Methods",
      description: "We rely on a math teaching approach that has been successful for hundreds of years, helping students build strong problem-solving skills and develop a deep understanding of fundamental concepts."
    },
    {
      icon: <ComputerIcon />,
      title: "Modern Technology for Customization",
      description: "Our platform uses technology to tailor the learning experience to each student's needs, adapting to their pace, strengths, and challenges—without compromising on quality."
    },
    {
      icon: <AttachMoneyIcon />,
      title: "Affordable for All",
      description: "By streamlining the learning process and cutting unnecessary costs, we're able to provide top-tier educational materials at a price that's affordable for everyone."
    }
  ];

  return (
    <MissionSectionWrapper>
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h1"
            sx={(theme) => ({
              color: 'common.white',
              mb: 3,
              fontSize: {
                xs: '2.5rem',
                sm: '3rem',
                md: '3.5rem'
              }
            })}
          >
            Affordable, High-Quality Math Education for Every Student
          </Typography>
          <Typography 
            variant="h5"
            sx={{
              color: 'common.white',
              opacity: 0.9,
              maxWidth: '800px',
              mx: 'auto',
              mb: 6
            }}
          >
            At MyDailyMath, our goal is to provide every student with access to affordable, high-quality math learning resources. By combining time-tested, traditional teaching methods with modern technology, we ensure a customized learning experience that is both effective and affordable.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <FeatureCard
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <IconWrapper>
                  {feature.icon}
                </IconWrapper>
                <Typography 
                  variant="h4" 
                  gutterBottom
                  sx={{ color: 'primary.main', mb: 2 }}
                >
                  {feature.title}
                </Typography>
                <Typography 
                  variant="body1"
                  color="text.secondary"
                >
                  {feature.description}
                </Typography>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MissionSectionWrapper>
  );
}

export default MissionStatement;