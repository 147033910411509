import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { 
  Tabs, Tab, Select, MenuItem, Button, Box, Typography, 
  Snackbar, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, Tooltip, Paper 
} from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { STRIPE_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY_TEST, MONTHLY_PRICES, MONTHLY_PRICES_TEST, ONE_TIME_PRICES, ONE_TIME_PRICES_TEST } from '../components/stripe';

// Use test keys in development, production keys in production
const isTestMode = process.env.NODE_ENV === 'development';
const stripePromise = loadStripe(isTestMode ? STRIPE_PUBLISHABLE_KEY_TEST : STRIPE_PUBLISHABLE_KEY);

function DonationForm() {
  const [donationType, setDonationType] = useState('monthly');
  const [amount, setAmount] = useState('11');
  const [customAmount, setCustomAmount] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const goalAmount = 10000;
  const currentAmount = 1375;

  const handleDonationTypeChange = (event, newValue) => {
    setDonationType(newValue);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const formatCurrency = (value) => {
    // Remove non-digit characters and leading zeros
    const digits = value.replace(/\D/g, '').replace(/^0+/, '');
    // Format with commas
    return digits.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    const formattedValue = formatCurrency(value);
    setCustomAmount(formattedValue);

    // Validation
    const numericValue = parseFloat(formattedValue.replace(/,/g, ''));
    if (isNaN(numericValue) || numericValue < 1) {
      setError('Minimum donation amount is $1');
    } else {
      setError('');
    }
  };

  const handleDonate = () => {
    const donationAmount = amount === 'other' ? customAmount : amount;
    if (!donationAmount || parseFloat(donationAmount.replace(/,/g, '')) < 1) {
      setError('Please enter a valid donation amount');
      return;
    }
    redirectToCheckout();
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    try {
      const stripe = await stripePromise;
      const donationAmount = amount;

      // Update the baseUrl based on environment
      const baseUrl = isTestMode 
        ? 'https://checkout.stripe.com'  // Development URL
        :  `${window.location.protocol}//${window.location.host}`; // Replace with your production domain

      const monthlyPrices = isTestMode ? MONTHLY_PRICES_TEST : MONTHLY_PRICES;
      const oneTimePrices = isTestMode ? ONE_TIME_PRICES_TEST : ONE_TIME_PRICES;

      if (donationType === 'monthly') {
        const priceId = monthlyPrices[donationAmount];
        if (!priceId) {
          throw new Error('Invalid subscription amount selected');
        }
        await stripe.redirectToCheckout({
          lineItems: [{
            price: priceId,
            quantity: 1,
          }],
          mode: 'subscription',
          successUrl: `${baseUrl}/success`,
          cancelUrl: `${baseUrl}/cancel`,
        });
      } else {
        const priceId = oneTimePrices[donationAmount];
        if (!priceId) {
          throw new Error('Invalid donation amount selected');
        }
        await stripe.redirectToCheckout({
          lineItems: [{
            price: priceId,
            quantity: 1,
          }],
          mode: 'payment',
          successUrl: `${baseUrl}/success`,
          cancelUrl: `${baseUrl}/cancel`,
        });
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 500, margin: 'auto', p: 4, borderRadius: 4, alignContent: 'center'  }}>
      <Box sx={{ mb: 4, textAlign: 'center', contentAlign: 'center' }}>

        <Typography variant="h4" component="h1" gutterBottom>
          Support Future of Education
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ fontStyle: 'italic', mb: 2 }}>
          "The best way to find yourself is to lose yourself in the service of others." - Mahatma Gandhi
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Help us reach our current goal of Monthly Support:</Typography>
        <Typography variant="h4" gutterBottom>
          ${currentAmount.toLocaleString('en-US')} / ${goalAmount.toLocaleString('en-US')}
        </Typography>
        <Box sx={{ width: '100%', backgroundColor: '#e0e0e0', height: 10, borderRadius: 5 }}>
          <Box 
            sx={{ 
              width: `${(currentAmount / goalAmount) * 100}%`, 
              backgroundColor: 'primary.main', 
              height: '100%', 
              borderRadius: 5 
            }} 
          />
        </Box>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {Math.round((currentAmount / goalAmount) * 100)}% of our goal reached
        </Typography>
      </Box>

      <Tabs value={donationType} onChange={handleDonationTypeChange} centered sx={{ mb: 3 }}>
        <Tab label="Monthly Support" value="monthly" />
        <Tab label="One-Time Gift" value="oneTime" />
      </Tabs>

      <Select value={amount} onChange={handleAmountChange} fullWidth sx={{ mb: 3 }}>
        <MenuItem value="5">$5</MenuItem>
        <MenuItem value="11">$11</MenuItem>
        <MenuItem value="23">$23</MenuItem>
        <MenuItem value="53">$53</MenuItem>
        <MenuItem value="101">$101</MenuItem>
        <MenuItem value="503">$503</MenuItem>
        <MenuItem value="1009">$1009</MenuItem>
      </Select>

      <Tooltip title={donationType === 'oneTime' ? "Make a one-time donation" : "Set up a recurring monthly donation"} arrow>
        <Button 
          variant="contained" 
          color="accent_two"
          fullWidth 
          onClick={handleDonate}
          sx={{ mb: 3, py: 1.5, color: 'white', fontWeight: 'bold' }}
        >
          {donationType === 'oneTime' ? 'Make Your Gift' : 'Start Monthly Support'} 
          {amount !== 'other' && ` of $${amount}`}
        </Button>
      </Tooltip>

      <Typography variant="body1" align="center" sx={{ mb: 3 }}>
        You are supporting ALFIROV EDUCATIONAL INITIATIVE, a 501(c)(3) non-profit organization. <br /> Cash donations are tax-deductible.
      </Typography>

      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Tooltip title="Support us on Patreon" arrow>
          <Button
            variant="outlined"
            color="secondary"
            href="https://www.patreon.com/your_page"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ flex: 1, mr: 2 }}
          >
            Patreon
          </Button>
        </Tooltip>
        <Tooltip title="Buy us a coffee" arrow>
          <Button
            variant="outlined"
            color="secondary"
            href="https://www.buymeacoffee.com/your_page"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ flex: 1 }}
          >
            Buy Me a Coffee
          </Button>
        </Tooltip>
      </Box> */}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Paper>
  );
}

export default DonationForm;