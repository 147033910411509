import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Alert,
  Divider,
  Link,
  useTheme,
  useMediaQuery,
  Container
} from '@mui/material';
import Cookies from 'js-cookie';

const COOKIE_CONSENT_KEY = 'cookie-consent-settings';
const DEFAULT_CONSENT = {
  essential: true, // Always true, can't be changed
  performance: false,
  functional: false,
  targeting: false,
  lastUpdated: null
};

const CookieBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [consent, setConsent] = useState(DEFAULT_CONSENT);

  // Load saved cookie preferences on mount
  useEffect(() => {
    const savedConsent = Cookies.get(COOKIE_CONSENT_KEY);
    if (!savedConsent) {
      setOpen(true);
    } else {
      try {
        setConsent(JSON.parse(savedConsent));
      } catch (e) {
        setOpen(true);
      }
    }
  }, []);

  const handleAcceptAll = () => {
    const newConsent = {
      ...DEFAULT_CONSENT,
      performance: true,
      functional: true,
      targeting: true,
      lastUpdated: new Date().toISOString()
    };
    saveConsent(newConsent);
    setOpen(false);
  };

  const handleAcceptSelected = () => {
    const newConsent = {
      ...consent,
      lastUpdated: new Date().toISOString()
    };
    saveConsent(newConsent);
    setPreferencesOpen(false);
    setOpen(false);
  };

  const handleRejectAll = () => {
    const newConsent = {
      ...DEFAULT_CONSENT,
      lastUpdated: new Date().toISOString()
    };
    saveConsent(newConsent);
    setOpen(false);
  };

  const saveConsent = (newConsent) => {
    setConsent(newConsent);
    Cookies.set(COOKIE_CONSENT_KEY, JSON.stringify(newConsent), { expires: 365 });
    // Implement cookie management based on consent
    manageCookies(newConsent);
  };

  const manageCookies = (settings) => {
    // Remove non-essential cookies if consent is withdrawn
    if (!settings.performance) {
      removeAnalyticsCookies();
    }
    if (!settings.functional) {
      removeFunctionalCookies();
    }
    if (!settings.targeting) {
      removeTargetingCookies();
    }
  };

  const removeAnalyticsCookies = () => {
    // Remove Google Analytics cookies
    ['_ga', '_gid', '_gat'].forEach(cookie => {
      Cookies.remove(cookie);
    });
  };

  const removeFunctionalCookies = () => {
    // Remove functional cookies
    ['preferences', 'language'].forEach(cookie => {
      Cookies.remove(cookie);
    });
  };

  const removeTargetingCookies = () => {
    // Remove targeting/advertising cookies
    ['_fbp', '_gcl_au'].forEach(cookie => {
      Cookies.remove(cookie);
    });
  };

  const CookiePreferences = () => (
    <Dialog 
      open={preferencesOpen}
      onClose={() => setPreferencesOpen(false)}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>Cookie Preferences</DialogTitle>
      <DialogContent>
        <Typography variant="body2" paragraph>
          We use cookies to enhance your experience and analyze our website traffic.
          You can customize your cookie preferences below.
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={consent.essential} disabled />
            }
            label={
              <Box>
                <Typography variant="subtitle2">Essential Cookies</Typography>
                <Typography variant="caption" display="block" color="text.secondary">
                  Required for basic website functionality. Cannot be disabled.
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 2 }} />

          <FormControlLabel
            control={
              <Switch 
                checked={consent.performance}
                onChange={(e) => setConsent({...consent, performance: e.target.checked})}
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">Performance Cookies</Typography>
                <Typography variant="caption" display="block" color="text.secondary">
                  Help us improve our website by collecting anonymous usage data.
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 2 }} />

          <FormControlLabel
            control={
              <Switch 
                checked={consent.functional}
                onChange={(e) => setConsent({...consent, functional: e.target.checked})}
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">Functional Cookies</Typography>
                <Typography variant="caption" display="block" color="text.secondary">
                  Enable enhanced functionality and personalization.
                </Typography>
              </Box>
            }
          />
          <Divider sx={{ my: 2 }} />

          <FormControlLabel
            control={
              <Switch 
                checked={consent.targeting}
                onChange={(e) => setConsent({...consent, targeting: e.target.checked})}
              />
            }
            label={
              <Box>
                <Typography variant="subtitle2">Targeting Cookies</Typography>
                <Typography variant="caption" display="block" color="text.secondary">
                  Used to deliver relevant educational content and track its effectiveness.
                </Typography>
              </Box>
            }
          />
        </FormGroup>

        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            For more information, please read our{' '}
            <Link href="/cookie-policy" color="primary">
              Cookie Policy
            </Link>
            .
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRejectAll} color="inherit">
          Reject All
        </Button>
        <Button onClick={handleAcceptSelected} variant="contained">
          Save Preferences
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          padding: 0,
          transform: 'none',
        }}
      >
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
            boxShadow: 3,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'stretch', sm: 'center' },
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" gutterBottom={isMobile}>
                  Cookie Settings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We use cookies to enhance your learning experience and analyze site traffic. 
                  You can customize your preferences or accept all cookies.
                </Typography>
              </Box>
              
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 1,
                  minWidth: { sm: '300px' },
                  justifyContent: { xs: 'stretch', sm: 'flex-end' },
                }}
              >
                <Button 
                  fullWidth={isMobile}
                  size="small" 
                  onClick={() => setPreferencesOpen(true)}
                  variant="outlined"
                  color="inherit"
                >
                  Cookie Settings
                </Button>
                <Button 
                  fullWidth={isMobile}
                  size="small"
                  onClick={handleRejectAll}
                  variant="outlined"
                  color="inherit"
                >
                  Reject All
                </Button>
                <Button 
                  fullWidth={isMobile}
                  size="small"
                  onClick={handleAcceptAll}
                  variant="contained"
                  color="primary"
                >
                  Accept All
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Snackbar>
      <CookiePreferences />
    </>
  );
};

export default CookieBanner;
