import React, { useState, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Link, Menu, MenuItem } from '@mui/material';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const timeoutRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(document.getElementById('about-menu-trigger'));
  };

  const handleMenuClose = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 100); // Small delay to allow movement to submenu
  };

  const cancelClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <AppBar position="sticky" sx={{ 
      backgroundColor: theme.palette.background.paper, 
      boxShadow: theme.shadows[3], 
      padding: theme.spacing(1, 0) 
    }}>
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left: Logo */}
        <img src="/Logo.png" alt="MyDailyMath Logo" component={Link} href="/" style={{ width: '180px', height: '40px' }} />
        

        {/* Hamburger Icon for Mobile */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton onClick={toggleMenu} sx={{ color: theme.palette.primary.main }}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </IconButton>
        </Box>

        {/* Center: Main Menu */}
        <Box component="nav" sx={{ display: { xs: isOpen ? 'block' : 'none', md: 'flex' }, gap: theme.spacing(3) }}>
          <Link href="/" variant="dark">
            MyDailyMath
          </Link>
          <Link href="/support-project" variant="dark">
            Support the Project
          </Link>
          <Link href="/hire-tutor" variant="dark">
            Hire Tutor
          </Link>
          <Link href="/Textbooks" variant="dark">
            Textbooks
          </Link>
          <Link href="/videos" variant="dark">
            Video Lessons
          </Link>
          <Box
            id="about-menu-trigger"
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
            sx={{ 
              position: 'relative',
              '&:hover': {
                '& .MuiMenu-root': {
                  display: 'block'
                }
              }
            }}
          >
            <Link 
              variant="dark" 
              sx={{ 
                cursor: 'pointer',
                color: Boolean(anchorEl) ? theme.palette.primary.main : 'inherit'
              }}
            >
              About Us
            </Link>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                onMouseEnter: cancelClose,
                onMouseLeave: handleMenuClose
              }}
              sx={{
                '& .MuiPaper-root': {
                  mt: 1,
                  minWidth: 180,
                  boxShadow: theme.shadows[3]
                }
              }}
              slotProps={{
                paper: {
                  onMouseEnter: cancelClose,
                  onMouseLeave: handleMenuClose,
                  sx: {
                    pointerEvents: 'auto'
                  }
                }
              }}
            >
              <MenuItem 
                component={Link} 
                href="/about"
                onClick={() => setAnchorEl(null)}
                sx={{ 
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main
                  }
                }}
              >
                About the Project
              </MenuItem>
              <MenuItem 
                component={Link} 
                href="/roadmap"
                onClick={() => setAnchorEl(null)}
                sx={{ 
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.primary.main
                  }
                }}
              >
                Our Roadmap
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* Right: Get Start Button */}
        <Box sx={{ display: { xs: isOpen ? 'block' : 'none', md: 'block' } }}>
          <Button 
            href="/get-start" 
            variant="contained" 
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              visibility: 'hidden',
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            Get Started
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
