import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#225378',
      dark: '#072C44',
    },
    secondary: {
      main: '#072C44',
    },
    accent_one: {
      main: '#FF6B00',
      dark: '#E66000',
    },
    accent_two: {
      main: '#FF7700'
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
      gradient: 'linear-gradient(135deg, #225378 0%, #072C44 100%)',
      overlay: 'rgba(0, 0, 0, 0.5)',
    },
    secondaryGradient: {
      gradient: 'linear-gradient(135deg, #FF7700 0%, #ea5459 100%)',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
      disabled: '#cccccc',
    },
    grey: {
      50: '#f5f5f5',
      100: '#e0e0e0',
      200: '#cccccc',
      300: '#b3b3b3',
      400: '#999999',
      500: '#666666',
      600: '#333333',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#388e3c',
    },
    divider: '#e0e0e0',
  },
  typography: {
    fontFamily: 'Rubik, Rubik One, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '0.01562em',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.7,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
    sectionTitle: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      marginBottom: '1rem',
    },
    sectionSubtitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.4,
      marginBottom: '2rem',
    },
  },
  spacing: 8,
  components: {
    MuiLink: {
      variants: [
        {
          props: { variant: 'dark' },
          style: {
            color: '#072C44',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              color: '#225378',
              textDecoration: 'underline',
            },
          },
        },
        {
          props: { variant: 'light' },
          style: {
            color: '#ffffff',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              color: '#f4f6f8',
              textDecoration: 'underline',
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#333333',
          boxShadow: 'none',
          borderBottom: '1px solid #e0e0e0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '10px 20px',
          fontSize: '0.875rem',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          '&.Mui-disabled': {
            bgcolor: '#b3b3b3',
            color: '#f5f5f5',
          },
        },
        containedPrimary: {
          backgroundColor: '#225378',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
        },
        containedSecondary: {
          backgroundColor: '#072C44',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#051e2f',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '80vw',
          '@media (min-width: 80vw)': {
            maxWidth: '80vw',
          },
          padding: '0 16px',
          '@media (min-width: 600px)': {
            padding: '0 24px',
          },
          '@media (min-width: 960px)': {
            padding: '0 32px',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          padding: '2rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: '"Love Ya Like A Sister", cursive',
          fontSize: '3.5rem',
          fontWeight: 700,
          lineHeight: 1.2,
          letterSpacing: '0.01562em',
        },
        h2: {
          fontFamily: '"Love Ya Like A Sister", cursive',
          fontSize: '2.5rem',
          fontWeight: 700,
          lineHeight: 1.3,
          letterSpacing: '-0.00833em',
        },
        body1: {
          fontFamily: 'Rubik, Rubik One, sans-serif',
          color: '#555555',
        },
        body2: {
          fontFamily: 'Rubik, Rubik One, sans-serif',
          color: '#555555',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            '&:hover fieldset': {
              borderColor: '#225378',
            },
          },
        },
      },
    },
  },
  custom: {
    sectionPadding: {
      padding: '4rem 0',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 600px)': {
        minHeight: 'fit-content',
        height: 'fit-content',
        padding: '48px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }
    },
    contentMaxWidth: '80vw',
    gradientOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
  },
});

export default theme;