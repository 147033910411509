import React, { useState } from 'react';
import { Box, Container, Typography, Grid, useTheme } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


const historyContent = [
  {
    title: "The Decline of the Education System and Its Impact on Our Future",
    backgroundImage: "https://example.com/image1.jpg",
    content: [
      {
        subtitle: "Historical Context:",
        text: "For centuries, education—especially math—has been at the core of human progress. But in recent decades, a shift has occurred. Driven by policies like 'No Child Left Behind' and the rise of digital tools, the emphasis on deep understanding and critical thinking has been replaced with a focus on quick results and test scores. The consequence is a generation of students who rely more on technology than on building foundational skills.",
        quote: "The consequence is a generation of students who rely more on technology than on building foundational skills."
      },
      {
        subtitle: "The Shift in Priorities:",
        text: "With the growing reliance on digital apps and gamified platforms, students have become passive learners, using technology for quick fixes rather than engaging deeply with the material. The focus on speed and efficiency has eroded the very skills that math education is meant to develop—problem-solving, logical thinking, and persistence.",
        quote: "The focus on speed and efficiency has eroded the very skills that math education is meant to develop—problem-solving, logical thinking, and persistence."
      },
      {
        subtitle: "Why This Is Critical for Humanity:",
        text: "As technologies like artificial intelligence (AI) become more complex and integrated into our lives, we will need highly skilled, well-educated professionals to develop and manage these advancements. If students fail to grasp fundamental concepts, they will be ill-prepared for the future. Without strong math and critical thinking skills, they will struggle to lead innovation in fields like AI, robotics, and biotechnology. The decline in education threatens not just individual futures, but humanity's collective ability to solve global challenges.",
        quote: "The decline in education threatens not just individual futures, but humanity's collective ability to solve global challenges."
      }
    ]
  },
  {
    title: "The Consequences of Losing Essential Skills",
    backgroundImage: "https://example.com/image2.jpg",
    content: [
      {
        subtitle: "The Importance of Cognitive Development:",
        text: "Math is more than just numbers—it's a way of developing critical cognitive abilities. The increasing reliance on technology to solve simple problems is eroding students' ability to engage with challenging material. Instead of working through problems, students often turn to quick solutions, which weakens their ability to think deeply and solve complex issues.",
        quote: "The increasing reliance on technology to solve simple problems is eroding students' ability to engage with challenging material."
      },
      {
        subtitle: "The Loss of Fine Motor Skills and Cognitive Growth:",
        text: "With digital devices dominating education, many children are no longer practicing essential fine motor skills, like handwriting. This may seem trivial, but fine motor skills play a crucial role in cognitive development. Writing by hand engages different parts of the brain than typing and helps with memory retention, focus, and learning. The shift away from these practices is limiting the full potential of students' cognitive growth.",
        quote: "The shift away from these practices is limiting the full potential of students' cognitive growth."
      },
      {
        subtitle: "Decline in Critical and Logical Thinking:",
        text: "As technology becomes more integrated into education, students are losing the ability to critically think and solve problems independently. Instead of working through challenging concepts, they can now simply ask a device or app for the answer. The result? A generation of students who struggle to apply knowledge in real-world situations.",
        quote: "A generation of students who struggle to apply knowledge in real-world situations."
      },
      {
        subtitle: "The Growing Dependence on AI:",
        text: "With the rise of AI technologies like ChatGPT, the problem is only getting worse. More and more students are relying on AI to do their homework for them, seeking quick answers rather than putting in the effort to truly learn. This reliance on AI for problem-solving is creating a culture of shortcuts, where students are losing the desire and capacity to put in the work required to build meaningful skills. As AI continues to improve, students may be tempted to outsource their thinking, further compounding the educational crisis.",
        quote: "This reliance on AI for problem-solving is creating a culture of shortcuts, where students are losing the desire and capacity to put in the work required to build meaningful skills."
      }
    ]
  },
  {
    title: "Our Mission to Preserve and Cultivate These Essential Skills for the Future",
    backgroundImage: "https://example.com/image3.jpg",
    content: [
      {
        subtitle: "Why We Created MyDailyMath:",
        text: "At MyDailyMath, we saw the dangers posed by this growing reliance on technology and the decline in real learning. Our mission is to restore traditional teaching methods that foster deep understanding and critical thinking—skills that are essential in an era of increasingly complex technologies.",
        quote: "We believe in blending time-tested methods with modern customization, providing students with personalized learning experiences that don't sacrifice the development of key cognitive abilities."
      },
      {
        subtitle: "Preparing for a Future of Complex Technologies:",
        text: "In a world increasingly driven by AI, robotics, and advanced tech, the demand for skilled, well-educated individuals has never been higher. MyDailyMath is designed to prepare students to not only understand these technologies but also to lead and innovate in these fields.",
        quote: "MyDailyMath is designed to prepare students to not only understand these technologies but also to lead and innovate in these fields."
      },
      {
        subtitle: "Why It Starts in Childhood:",
        text: "Cognitive development during childhood is the foundation for lifelong learning and success. By starting early, we can ensure that students build the mental resilience needed to navigate the challenges of an ever-changing technological landscape.",
        quote: "Cognitive development during childhood is the foundation for lifelong learning and success."
      },
      {
        subtitle: "Our Commitment to the Next Generation:",
        text: "We believe that ensuring the next generation has access to high-quality, affordable math education is essential for their personal success and the progress of humanity. Preserving and passing down these critical skills is not just about teaching math—it's about shaping the problem-solvers, innovators, and leaders of tomorrow.",
        quote: "MyDailyMath is committed to equipping students with the skills they need to thrive in a world where technological mastery will define success."
      }
    ]
  }
];

const ContentBlock = ({ subtitle, text, quote }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ 
      mb: 4,
      p: 4,
      bgcolor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
      minHeight: '30vh',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography 
        variant="h5" 
        sx={{ 
          fontWeight: 800,
          mb: 3,
          fontSize: { xs: '1.5rem', md: '1.75rem' },
          color: theme.palette.primary.main,
          position: 'relative',
          lineHeight: 1.4,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-12px',
            left: 0,
            width: '80px',
            height: '4px',
            backgroundColor: theme.palette.accent_one.main
          }
        }}
      >
        {subtitle}
      </Typography>
      
      <Typography 
        variant="body1" 
        sx={{ 
          mb: 4,
          lineHeight: 1.8,
          fontSize: { xs: '1rem', md: '1.1rem' },
          color: theme.palette.text.primary
        }}
      >
        {text}
      </Typography>
      
      {quote && (
        <Box
          sx={{
            borderLeft: `6px solid ${theme.palette.accent_one.main}`,
            pl: 4,
            py: 2,
            mt: 'auto',
            bgcolor: theme.palette.background.default,
            borderRadius: '0 12px 12px 0',
          }}
        >
          <Typography 
            variant="h6"
            sx={{ 
              fontStyle: 'italic',
              fontWeight: 300,
              color: theme.palette.grey[400],
              fontSize: { xs: '1.1rem', md: '1.25rem' },
              lineHeight: 1
            }}
          >
            {quote}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SliderDots = ({ total, active, onChange }) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        gap: 2,
        justifyContent: 'center',
        mt: { xs: 6, md: 8 }
      }}
    >
      {[...Array(total)].map((_, i) => (
        <Box
          key={i}
          onClick={() => onChange(i)}
          sx={{
            width: 16,
            height: 16,
            borderRadius: '50%',
            bgcolor: i === active ? 'accent_one.main' : 'grey.300',
            cursor: 'pointer',
            transition: 'all 0.3s',
            '&:hover': {
              transform: 'scale(1.2)',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }
          }}
        />
      ))}
    </Box>
  );
};

function HistorySection() {
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Box sx={{
      width: '100%',
      bgcolor: theme.palette.background.default,
      py: { xs: 8, md: 12 },
      position: 'relative'
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          align="center" 
          sx={{ 
            mb: { xs: 6, md: 10 },
            fontWeight: 900,
            color: theme.palette.primary.main,
            fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem' },
            lineHeight: { xs: 1.2, md: 1.1 },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '120px',
              height: '6px',
              backgroundColor: theme.palette.accent_one.main,
              borderRadius: '3px'
            }
          }}
        >
          {historyContent[activeSlide].title}
        </Typography>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeSlide}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.5 }}
          >
            <Grid container spacing={{ xs: 4, md: 6 }}>
              <Grid item xs={12} md={6}>
                {historyContent[activeSlide].content.slice(0, 2).map((item, index) => (
                  <ContentBlock 
                    key={index}
                    subtitle={item.subtitle}
                    text={item.text}
                    quote={item.quote}
                  />
                ))}
              </Grid>
              
              <Grid item xs={12} md={6}>
                {historyContent[activeSlide].content.slice(2, 4).map((item, index) => (
                  <ContentBlock 
                    key={index + 2}
                    subtitle={item.subtitle}
                    text={item.text}
                    quote={item.quote}
                  />
                ))}
              </Grid>
            </Grid>
          </motion.div>
        </AnimatePresence>

        <SliderDots 
          total={historyContent.length} 
          active={activeSlide} 
          onChange={setActiveSlide}
        />
      </Container>

      {/* Navigation Arrows */}
      <Box sx={{ 
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        px: 4,
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
      }}>
        <Box 
          onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
          sx={{
            cursor: 'pointer',
            pointerEvents: 'auto',
            p: 2,
            borderRadius: '50%',
            bgcolor: 'background.paper',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            '&:hover': { 
              transform: 'scale(1.1)',
              boxShadow: '0 4px 25px rgba(0,0,0,0.2)'
            }
          }}
        >
          <NavigateBeforeIcon sx={{ fontSize: '2rem' }} />
        </Box>
        <Box 
          onClick={() => setActiveSlide(prev => Math.min(historyContent.length - 1, prev + 1))}
          sx={{
            cursor: 'pointer',
            pointerEvents: 'auto',
            p: 2,
            borderRadius: '50%',
            bgcolor: 'background.paper',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            '&:hover': { 
              transform: 'scale(1.1)',
              boxShadow: '0 4px 25px rgba(0,0,0,0.2)'
            }
          }}
        >
          <NavigateNextIcon sx={{ fontSize: '2rem' }} />
        </Box>
      </Box>
    </Box>
  );
}

export default HistorySection;