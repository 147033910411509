import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SuccessPage() {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center', py: 8 }}>
      <Typography variant="h4" gutterBottom>
        Thank You for Your Support!
      </Typography>
      <Typography variant="body1" paragraph>
        Your donation has been processed successfully.
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => navigate('/')}
      >
        Return Home
      </Button>
    </Box>
  );
}

export default SuccessPage; 