import React, { useState } from 'react';
import { Box, Typography, Container, Grid, Button, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import WorkIcon from '@mui/icons-material/Work';
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';


// TODO: Fix the height for mobile

const valuePoints = [
  {
    icon: <SchoolIcon />,
    title: "Build Math Foundations",
    description: "Develop critical thinking skills essential for navigating today's complex world.",
    detailedDescription: "Strong mathematical foundations are crucial for academic success. Our program ensures your child masters fundamental concepts through structured learning paths and engaging exercises that build confidence and competency.Strong mathematical foundations are crucial for academic success. Our program ensures your child masters fundamental concepts through structured learning paths and engaging exercises that build confidence and competency.Strong mathematical foundations are crucial for academic success. Our program ensures your child masters fundamental concepts through structured learning paths and engaging exercises that build confidence and competency.",
    image: "/images/math-foundations.svg"
  },
  {
    icon: <EmojiObjectsIcon />,
    title: "Boost Problem-Solving",
    description: "Equip your child with skills to tackle real-world challenges confidently.",
    detailedDescription: "Problem-solving is a crucial skill for academic success. Our program focuses on developing your child's problem-solving abilities through engaging exercises and real-world applications.",
    image: "/images/problem-solving.svg"
  },
  {
    icon: <WorkIcon />,
    title: "Prepare for Future Careers",
    description: "Develop skills needed for high-paying STEM careers of tomorrow.",
    detailedDescription: "STEM careers are in high demand, and our program prepares your child for these careers by developing essential skills in math, science, technology, engineering, and more.",
    image: "/images/future-jobs.svg"
  },
  {
    icon: <BlockIcon />,
    title: "Eliminate Distractions",
    description: "Focus on real learning without digital distractions, ensuring true understanding.",
    detailedDescription: "Digital distractions can hinder learning. Our program focuses on developing your child's ability to focus and concentrate on real learning without digital distractions.",
    image: "/images/focused-learning.svg"
  },
  {
    icon: <WarningIcon />,
    title: "Prevent Math Struggles",
    description: "Avoid limiting future opportunities by building strong math skills early.",
    detailedDescription: "Building strong math skills early can prevent future math struggles. Our program focuses on developing your child's math skills through structured learning paths and engaging exercises.",
    image: "/images/math-crisis.svg"
  },
  {
    icon: <StarIcon />,
    title: "Unlock Full Potential",
    description: "Empower your child with essential math skills for academic and future success.",
    detailedDescription: "Essential math skills are crucial for academic and future success. Our program focuses on developing your child's essential math skills through structured learning paths and engaging exercises.",
    image: "/images/full-potential.svg"
  }
];

const StyledGridItem = styled(Box)(({ theme, isActive }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  border: isActive ? `2px solid ${theme.palette.accent_one.main}` : 'none',
  backgroundImage: `
    linear-gradient(${isActive ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.03)'} 1px, transparent 1px),
    linear-gradient(90deg, ${isActive ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.03)'} 1px, transparent 1px)
  `,
  backgroundSize: '20px 20px',
  boxShadow: isActive 
    ? `0 8px 16px ${theme.palette.accent_one.main}40`
    : '0 2px 4px rgba(0,0,0,0.1)',
  width: '100%',
  minWidth: '280px',
  maxWidth: '350px',
  
  // Responsive styles
  [theme.breakpoints.up('md')]: {
    height: '140px',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-5px) scale(1.1)',
      border: `2px solid ${theme.palette.accent_one.main}`,
      boxShadow: isActive 
        ? `0 12px 20px ${theme.palette.accent_one.main}60`
        : '0 6px 12px rgba(0,0,0,0.15)',
    }
  },
  
  // Mobile styles
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    minHeight: '120px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '100%',
    maxWidth: '100%',
    '&:active': {
      backgroundColor: theme.palette.background.paper,
      transform: 'scale(0.98)',
    }
  }
}));

const IconCircle = styled(Box)(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isActive ? theme.palette.accent_one.main : theme.palette.primary.main,
  transition: 'all 0.3s',
  borderRadius: '50%',
  aspectRatio: '1 / 1',
  
  // Responsive sizing
  [theme.breakpoints.up('md')]: {
    width: '50px',
    '& svg': {
      fontSize: '1.75rem'
    }
  },
  
  [theme.breakpoints.down('md')]: {
    width: '40px',
    '& svg': {
      fontSize: '1.25rem'
    }
  }
}));

const AnimatedCard = styled(Box)(({ theme, isFlipped }) => ({
  perspective: '1000px',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
  position: 'relative',
  width: '100%',
  height: '100%',
}));

const CardFace = styled(Box)(({ theme, isBack }) => ({
  position: 'absolute',
  width: '100%',
  minHeight: '100%',
  backfaceVisibility: 'hidden',
  transform: isBack ? 'rotateY(180deg)' : 'rotateY(0)',
}));

function ValuePoint({ icon, title, isActive, onClick }) {
  const theme = useTheme();
  
  return (
    <StyledGridItem isActive={isActive} onClick={onClick}>
      <IconCircle isActive={isActive}>
        <Box sx={{ 
          color: theme.palette.common.white,
          transition: 'all 0.3s',
          transform: isActive ? 'scale(1.1)' : 'scale(1)',
        }}>
          {icon}
        </Box>
      </IconCircle>
      <Typography 
        variant="h6" 
        align="center"
        sx={{ 
          fontSize: { 
            xs: '0.9rem',
            sm: '1rem',
            md: '1.1rem'
          }, 
          fontWeight: 'bold',
          color: theme.palette.grey[600],
          transition: 'color 0.3s',
          lineHeight: 1.2,
          mt: { xs: 1, sm: 2 }
        }}
      >
        {title}
      </Typography>
    </StyledGridItem>
  );
}

function ValuePropositionSection() {
  const theme = useTheme();
  const [activePoint, setActivePoint] = useState(null);

  return (
    <Box 
      component="section" 
      sx={{ 
        position: 'relative',
        bgcolor: theme.palette.background.default,
        backgroundImage: `linear-gradient(45deg, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
        py: { xs: 12, sm: 6, md: 8 },
        overflow: 'visible',
        height: 'auto',
        pb: { xs: '600px', sm: '600px', md: '800px' }  // Add margin bottom to create space for next section
       
      }}
    >
      <Container sx={{
        px: { xs: 2, sm: 3, md: 6 },
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 4, sm: 6 }
      }}>
        <Typography 
          variant="h1" 
          align="center" 
          sx={{ 
            fontWeight: 900, 
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }, 
            color: theme.palette.primary.main,
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Don't Let Your Child Fall Behind
        </Typography>
        
        <Typography 
          variant="h4" 
          align="center" 
          sx={{ 
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' }
          }}
        >
          Master Math Today - Secure their future with strong math foundations
        </Typography>

        <Grid container spacing={{ xs: 3, sm: 4 }} alignItems="flex-start">
          {/* Left side - Image/Content */}
          <Grid item xs={12} lg={5} sx={{ 
            order: { xs: 2, lg: 1 },
            height: 'auto'
          }}>
            <AnimatedCard isFlipped={activePoint !== null}>
              <CardFace>
                <Box
                  component="img"
                  src="math-person.svg"
                  alt="Math Learning Illustration"
                  sx={{
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    align: 'center',
                    objectFit: 'contain',
                    transition: 'all 0.3s',
                  }}
                />
              </CardFace>
              <CardFace isBack>
                <Box sx={{
                  p: { xs: 2, sm: 3, md: 4 },
                  height: '100%',
                  bgcolor: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[4],
                  overflow: 'auto'  // Allow scrolling if content is too long
                }}>
                  {activePoint !== null && (
                    <>
                      <Typography 
                        variant="h4" 
                        gutterBottom 
                        color="primary.main"
                        sx={{ 
                          mb: 3,
                          fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
                        }}
                      >
                        {valuePoints[activePoint].title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                          lineHeight: 1.6 
                        }}
                      >
                        {valuePoints[activePoint].detailedDescription}
                      </Typography>
                    </>
                  )}
                </Box>
              </CardFace>
            </AnimatedCard>
          </Grid>

          {/* Right side - Value Points Grid */}
          <Grid item xs={12} lg={7} sx={{ 
            order: { xs: 1, lg: 2 }
          }}>
            <Grid 
              container 
              spacing={{ xs: 2, sm: 3 }}
              sx={{ 
                justifyContent: 'center',
                height: '100%'
              }}
            >
              {valuePoints.map((point, index) => (
                <Grid 
                  item 
                  xs={12}
                  sm={6}
                  lg={6}
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <ValuePoint
                    {...point}
                    isActive={activePoint === index}
                    onClick={() => setActivePoint(activePoint === index ? null : index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ValuePropositionSection;
