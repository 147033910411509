import React from 'react';
import { Box, Container, Typography, Breadcrumbs, Link } from '@mui/material';
import VideoGallery from '../components/VideoSection';
import { useTheme } from '@mui/material/styles';

function VideosPage() {
  const theme = useTheme();

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      {/* Hero Section */}
        <Container maxWidth="xl">
          
        {/* Video Gallery Component */}
        <VideoGallery />
      </Container>
    </Box>
  );
}

export default VideosPage;
