import React from 'react';
import { Box, Container, Typography, Grid, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';

const PhaseCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  position: 'relative',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  overflow: 'visible',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
  '&::before': {
    content: 'attr(data-phase)',
    position: 'absolute',
    top: -20,
    right: -20,
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    width: 72,
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '1.75rem',
    boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
    zIndex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    '&::before': {
      width: 48,
      height: 48,
      fontSize: '1.5rem',
      top: -24,
      right: 16,
    }
  }
}));

const PhaseTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.75rem',
  fontWeight: 800,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  lineHeight: 1.3,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  }
}));

const PhaseSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  color: theme.palette.accent_one.main,
  marginBottom: theme.spacing(3),
  position: 'relative',
  paddingBottom: theme.spacing(2),
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '60px',
    height: '3px',
    backgroundColor: theme.palette.accent_one.main,
    borderRadius: theme.shape.borderRadius
  }
}));

const PointsList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  '& li': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    fontSize: '1.1rem',
    lineHeight: 1.6,
    color: theme.palette.text.primary,
    '&::before': {
      content: '""',
      minWidth: '8px',
      height: '8px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      marginTop: '0.7rem'
    }
  }
}));

const ConnectedGrid = styled(motion(Grid))(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: -16,
    width: 32,
    height: 2,
    background: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  '&:last-child::after': {
    display: 'none'
  }
}));

const RoadMapSection = () => {
  const phases = [
    {
      title: "Phase 1: Laying the Foundation",
      subtitle: "Goal: Develop high-quality, affordable math materials",
      points: [
        "Middle Schools: Comprehensive textbooks, practice books, and teacher guides",
        "High Schools: Advanced materials to prepare students for higher education",
        "Elementary Schools: Fun, engaging resources to build a strong math foundation"
      ]
    },
    {
      title: "Phase 2: Expanding Horizons",
      subtitle: "Goal: Broaden our impact with STEM subjects",
      points: [
        "Physics: Simplified concepts with step-by-step problem-solving guides",
        "Chemistry: Practice-focused materials with real-world applications",
        "Biology: Clear, visual guides for better understanding",
        "Computer Science: Coding essentials for the next generation"
      ]
    },
    {
      title: "Phase 3: Bringing Learning to Life",
      subtitle: "Goal: Record full course lectures for the entire math curriculum",
      points: [
        "Engaging Lessons: Comprehensive video content for all levels",
        "Step-by-Step Explanations: Ensure clarity and deeper understanding",
        "Global Access: Make learning accessible to everyone, anywhere"
      ]
    },
    {
      title: "Phase 4: Personalized Learning for All",
      subtitle: "Goal: Develop the AI learning assistance platform",
      points: [
        "For Students: Customized learning paths that adapt to individual needs",
        "For Teachers: Tools to provide focused attention on every student",
        "For Parents: Progress-tracking dashboards for peace of mind"
      ]
    },
    {
      title: "Phase 5: Scaling to Global Impact",
      subtitle: "Goal: Expand access to every child, everywhere",
      points: [
        "Localize materials for different regions and languages",
        "Collaborate with schools to integrate AI-driven solutions",
        "Launch scholarships and free resources for underserved communities"
      ]
    }
  ];

  return (
    <Box sx={(theme) => ({
      ...theme.custom.sectionPadding,
      background: theme.palette.background.default
    })}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
          <Typography 
            variant="h1" 
            gutterBottom 
            sx={(theme) => ({
              fontSize: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3.5rem'
              },
              fontWeight: 900,
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(3)
            })}
          >
            Our Roadmap to Revolutionize Math Education
          </Typography>
          <Typography 
            variant="h5" 
            color="text.secondary" 
            sx={{ 
              mb: 4,
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              maxWidth: '800px',
              margin: '0 auto'
            }}
          >
            Together, We Can Build the Future of Learning
          </Typography>
        </Box>

        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <Grid container spacing={4}>
            {phases.map((phase, index) => (
              <ConnectedGrid
                item
                xs={12} 
                md={6} 
                lg={4} 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <PhaseCard data-phase={index + 1} elevation={4}>
                  <PhaseTitle variant="h4">
                    {phase.title}
                  </PhaseTitle>
                  <PhaseSubtitle>
                    {phase.subtitle}
                  </PhaseSubtitle>
                  <PointsList component="ul" sx={{ pl: 0, m: 0, listStyle: 'none' }}>
                    {phase.points.map((point, i) => (
                      <Typography
                        component="li"
                        key={i}
                      >
                        {point}
                      </Typography>
                    ))}
                  </PointsList>
                </PhaseCard>
              </ConnectedGrid>
            ))}
          </Grid>
        </ErrorBoundary>

        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Your Support Makes This Possible
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Join us in transforming education. Together, we can give every child the tools they need to succeed in math and beyond.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default RoadMapSection;
