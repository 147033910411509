import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Fade, Checkbox, FormGroup, FormControlLabel, LinearProgress, useMediaQuery, Alert, Snackbar } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(6),
  width: '100%',
  maxWidth: 600,
  boxShadow: theme.shadows[10],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-shrink': {
    background: theme.palette.background.paper,
    padding: '0 8px',
  },
}));

const StepWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  minHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 4),
  fontSize: '1.1rem',
}));

const steps = [
  { 
    label: 'Select Grade', 
    type: 'select', 
    name: 'grade', 
    options: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], 
    required: true 
  },
  { 
    label: 'Select Subject', 
    type: 'select', 
    name: 'subject', 
    options: ['Math', 'Algebra', 'Geometry', 'Calculus'], 
    required: true 
  },
  { 
    label: 'Your Name', 
    type: 'text', 
    name: 'name', 
    required: true 
  },
  { 
    label: 'Your Email', 
    type: 'email', 
    name: 'email', 
    required: true 
  },
  { 
    label: 'Your Phone', 
    type: 'tel', 
    name: 'phone', 
    required: true 
  },
  { 
    label: 'Preferred Teaching Method', 
    type: 'select', 
    name: 'teachingMethod', 
    options: ['In-Person', 'Online', 'Both'], 
    required: true 
  },
  { 
    label: 'ZIP Code', 
    type: 'text', 
    name: 'zip', 
    condition: (data) => data.teachingMethod !== 'Online', 
    required: true, 
    helperText: 'Enter your 5-digit ZIP code' 
  },
  { 
    label: 'Preferred Contact Method', 
    type: 'select', 
    name: 'preferredContact', 
    options: [
      { label: 'Phone call', value: 'phone' },
      { label: 'Email', value: 'email' },
      { label: 'Text message', value: 'text' }
    ], 
    required: true 
  },
];

function HireTutorSection() {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setErrors({});
  }, [currentStep]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'zip') {
      // For ZIP code: allow only numbers and limit to 5 digits
      const zipValue = value.replace(/\D/g, '').slice(0, 5);
      setFormData({ ...formData, [name]: zipValue });
    } else if (name === 'phone') {
      // For phone: let InputMask handle the formatting
      setFormData({ ...formData, [name]: value });
    } else {
      // For all other fields: use the original behavior
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const updatedMethods = formData[name] || [];
    if (checked) {
      updatedMethods.push(value);
    } else {
      const index = updatedMethods.indexOf(value);
      if (index > -1) {
        updatedMethods.splice(index, 1);
      }
    }
    setFormData({ ...formData, [name]: updatedMethods });
  };

  const validateStep = () => {
    const currentStepData = steps[currentStep];
    let stepErrors = {};

    if (currentStepData.required) {
      if (currentStepData.type === 'checkbox') {
        if (!formData[currentStepData.name] || formData[currentStepData.name].length === 0) {
          stepErrors[currentStepData.name] = 'Please select at least one option';
        }
      } else if (!formData[currentStepData.name]) {
        stepErrors[currentStepData.name] = 'This field is required';
      }
    }

    if (currentStepData.name === 'zip' && formData.zip) {
      const zipRegex = /^\d{5}$/;
      if (!zipRegex.test(formData.zip)) {
        stepErrors.zip = 'Please enter a valid 5-digit ZIP code';
      }
    }

    if (currentStepData.name === 'email' && formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        stepErrors.email = 'Please enter a valid email address';
      }
    }

    if (currentStepData.name === 'phone' && formData.phone) {
      const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
      if (!phoneRegex.test(formData.phone)) {
        stepErrors.phone = 'Please enter a valid phone number';
      }
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;

    try {
      setIsLoading(true);
      setErrors({});

      const response = await fetch(`${API_URL}/form/tutor-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          grade: formData.grade,
          subject: formData.subject,
          teachingMethod: formData.teachingMethod,
          zip: formData.zip,
          preferredContact: formData.preferredContact
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.errors?.[0]?.msg || 'Failed to submit form');
      }

      // Success handling
      setSnackbarSeverity('success');
      setSnackbarMessage('Thank you! Your tutor request has been submitted successfully.');
      setOpenSnackbar(true);
      
      // Reset form
      setFormData({});
      setCurrentStep(0);

    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(error.message);
      setOpenSnackbar(true);
      console.error('Form submission error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentStepData = steps[currentStep];

  const renderStepContent = () => {
    if (!currentStepData) return null;

    switch (currentStepData.type) {
      case 'select':
        return (
          <StyledFormControl fullWidth error={!!errors[currentStepData.name]}>
            <InputLabel>{currentStepData.label}</InputLabel>
            <Select
              name={currentStepData.name}
              value={formData[currentStepData.name] || ''}
              onChange={handleChange}
            >
              {currentStepData.options.map((option) => (
                <MenuItem 
                  key={typeof option === 'object' ? option.value : option} 
                  value={typeof option === 'object' ? option.value : option}
                >
                  {typeof option === 'object' ? option.label : option}
                </MenuItem>
              ))}
            </Select>
            {errors[currentStepData.name] && (
              <Typography color="error" variant="caption">
                {errors[currentStepData.name]}
              </Typography>
            )}
          </StyledFormControl>
        );
      case 'checkbox':
        return (
          <FormGroup>
            {currentStepData.options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={formData[currentStepData.name]?.includes(option) || false}
                    onChange={handleCheckboxChange}
                    name={currentStepData.name}
                    value={option}
                  />
                }
                label={option}
              />
            ))}
            {errors[currentStepData.name] && (
              <Typography color="error" variant="caption">
                {errors[currentStepData.name]}
              </Typography>
            )}
          </FormGroup>
        );
      default:
        if (currentStepData.name === 'phone') {
          return (
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData[currentStepData.name] || ''}
              onChange={handleChange}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label={currentStepData.label}
                  name={currentStepData.name}
                  error={!!errors[currentStepData.name]}
                  helperText={errors[currentStepData.name] || 'Format: +1 (xxx) xxx-xxxx'}
                />
              )}
            </InputMask>
          );
        } else if (currentStepData.name === 'zip') {
          return (
            <TextField
              fullWidth
              label={currentStepData.label}
              name={currentStepData.name}
              value={formData[currentStepData.name] || ''}
              onChange={handleChange}
              error={!!errors[currentStepData.name]}
              helperText={errors[currentStepData.name] || 'Enter 5-digit ZIP code'}
              inputProps={{
                maxLength: 5,
                pattern: '[0-9]*',
                inputMode: 'numeric'
              }}
              onKeyPress={(e) => {
                // Allow only numbers
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          );
        }
        return (
          <TextField
            fullWidth
            label={currentStepData.label}
            name={currentStepData.name}
            type={currentStepData.type}
            value={formData[currentStepData.name] || ''}
            onChange={handleChange}
            error={!!errors[currentStepData.name]}
            helperText={errors[currentStepData.name] || currentStepData.helperText}
          />
        );
    }
  };

  return (
    <SectionWrapper>
      <Container maxWidth="sm">
        <FormCard>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
            Hire a Math Tutor
          </Typography>
          <LinearProgress variant="determinate" value={(currentStep / (steps.length - 1)) * 100} sx={{ mb: 4 }} />
          <form onSubmit={handleSubmit}>
            <Fade in={true} timeout={500}>
              <StepWrapper>
                <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                  {currentStepData.label}
                </Typography>
                {renderStepContent()}
              </StepWrapper>
            </Fade>
            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid item xs={6}>
                {currentStep > 0 && (
                  <StyledButton 
                    onClick={handleBack} 
                    variant="outlined" 
                    startIcon={<ArrowBack />}
                    fullWidth
                  >
                    Back
                  </StyledButton>
                )}
              </Grid>
              <Grid item xs={6}>
                {currentStep < steps.length - 1 ? (
                  <StyledButton 
                    onClick={handleNext} 
                    variant="contained" 
                    color="primary" 
                    endIcon={<ArrowForward />}
                    fullWidth
                  >
                    Next
                  </StyledButton>
                ) : (
                  <StyledButton 
                    type="submit" 
                    variant="contained" 
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          </form>
          
          {/* Add Snackbar for notifications */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              variant="filled"
              icon={snackbarSeverity === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
              sx={{
                width: '100%',
                maxWidth: '500px',
                alignItems: 'center',
                '& .MuiAlert-icon': {
                  fontSize: '2rem',
                  marginRight: 2,
                },
                '& .MuiAlert-message': {
                  fontSize: '1rem',
                  fontWeight: 500,
                },
                boxShadow: (theme) => theme.shadows[3],
                borderRadius: '12px',
                ...(snackbarSeverity === 'success' && {
                  backgroundImage: (theme) => 
                    `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.dark})`,
                }),
                ...(snackbarSeverity === 'error' && {
                  backgroundImage: (theme) => 
                    `linear-gradient(135deg, ${theme.palette.error.main}, ${theme.palette.error.dark})`,
                }),
              }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </FormCard>
      </Container>
    </SectionWrapper>
  );
}

export default HireTutorSection;