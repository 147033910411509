import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Button, useMediaQuery, Grid } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import educationSvg from '../assets/Img/Education.svg';
import digitalToolsSvg from '../assets/Img/Digital_Tools_Overuse.svg';
import skillGapSvg from '../assets/Img/Confusion2SVG.svg';
import decliningScoresSvg from '../assets/Img/ConfusionSVG.svg';
import lackOfEngagementSvg from '../assets/Img/TeachingSVG.svg';
import skillsSvg from '../assets/Img/SkillsSVG.svg';

const slides = [
  {
    title: "Declining Math and Science Scores",
    description: (
      <>
        U.S. students are <strong>lagging behind</strong> in critical areas like math and science, jeopardizing their future competitiveness in a tech-driven world. These subjects form the backbone of STEM education, yet many students are struggling to achieve proficiency. Without a solid foundation in math and science, they may struggle to innovate and compete in industries like technology, engineering, and healthcare, where these skills are essential.

        Recent studies show that the gap between U.S. students and their international counterparts is widening, particularly in math scores. <strong>Falling test scores</strong> indicate a significant issue in how math and science are taught, with too much emphasis on rote memorization rather than real-world problem-solving. The consequences of this failure will be felt across generations, as students are left unprepared to tackle the challenges of the future economy.
      </>
    ),
    image: decliningScoresSvg
  },
  {
    title: "Over-Reliance on Digital Tools",
    description: (
      <>
        The increasing dependence on <strong>digital tools and apps</strong> for education is stunting the development of critical thinking skills among students. While technology offers convenience, it often leads students to rely on it for basic tasks like calculations and problem-solving, without truly understanding the underlying concepts. This dependency on digital platforms for education is making students passive learners, which hinders their ability to tackle more complex problems independently.

        Research has shown that excessive use of digital tools, particularly in math education, <strong>erodes cognitive skills</strong> that are necessary for success in advanced fields. Students may perform well in app-based assessments, but they are less likely to develop <strong>deep, conceptual understanding</strong> of key principles. The inability to think critically and apply knowledge in practical scenarios will have long-term consequences on their ability to innovate and lead in future industries.
      </>
    ),
    image: digitalToolsSvg
  },
  {
    title: "Widening Skill Gap",
    description: (
      <>
        The disconnect between the skills students are learning and the demands of the modern workforce is creating a <strong>widening skill gap</strong>. As industries evolve, particularly in STEM fields, the knowledge required to fill high-demand roles is not being met by current educational standards. The result is a growing population of graduates who are unprepared for the workforce, leading to <strong>increased unemployment rates</strong> and a stagnating economy.

        This skill gap is not only a personal problem for students but a national crisis. <strong>STEM-related jobs</strong> are projected to grow at a rapid pace, yet students lack the practical and theoretical knowledge necessary to compete in these industries. Without immediate changes to the education system, the U.S. risks <strong>falling behind</strong> in global innovation and economic growth, unable to keep up with countries that are prioritizing STEM education.
      </>
    ),
    image: skillGapSvg
  },
  {
    title: "Lack of Engagement in STEM",
    description: (
      <>
        Interest in <strong>STEM fields</strong> (Science, Technology, Engineering, and Math) is rapidly declining among students, particularly at the high school level. This lack of engagement is deeply concerning because STEM fields are the <strong>cornerstone of innovation</strong> and the drivers of future economic growth. Despite the growing demand for professionals in these areas, fewer students are choosing to pursue STEM degrees, leading to a shortage of qualified individuals.

        A major contributor to this disengagement is the way STEM subjects are being taught. Outdated teaching methods and a lack of real-world application are making these subjects seem irrelevant to students. <strong>Inspiring future engineers and scientists</strong> requires more than textbooks and lectures—it requires hands-on learning and meaningful connections to the world outside the classroom. If we fail to reignite interest in STEM education, we risk facing a future where critical industries lack the talent they need to thrive.
      </>
    ),
    image: lackOfEngagementSvg
  },
  {
    title: "Inadequate Problem-Solving Skills",
    description: (
      <>
        One of the biggest challenges facing students today is the lack of <strong>real-world problem-solving skills</strong>. In an era of rapidly evolving industries and complex global challenges, the ability to think critically and creatively is more important than ever. However, many students struggle to apply the knowledge they learn in the classroom to <strong>practical, real-life scenarios</strong>, leaving them underprepared for the challenges they will face in the workforce.

        Schools have increasingly shifted their focus to standardized testing, which often prioritizes memorization over <strong>critical thinking</strong>. As a result, students may pass exams, but they lack the ability to analyze problems, generate solutions, and adapt to unforeseen challenges. This deficiency in problem-solving is creating a workforce that is not equipped to innovate or lead in the modern economy, putting both individual careers and the nation’s global standing at risk.
      </>
    ),
    image: skillsSvg
  }
];

const OverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '5%',
  top: '60%',
  transform: 'translateY(-50%)',
  width: '40%',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  borderRadius: theme.shape.borderRadius * 8,
  padding: theme.spacing(6),
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.2)',
  minHeight: '50vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(4),
  zIndex: 2,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    left: '5%',
    top: '5%',
    transform: 'none',
    minHeight: '60vh',
  },
}));

const NavigationDot = styled(Button)(({ theme, active }) => ({
  width: 12,
  height: 12,
  minWidth: 12,
  borderRadius: '50%',
  padding: 0,
  margin: theme.spacing(0, 0.5),
  backgroundColor: active ? theme.palette.accent_one.main : theme.palette.grey[300],
  '&:hover': {
    backgroundColor: active ? theme.palette.accent_one.dark : theme.palette.grey[400],
    transform: 'scale(1.2)',
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));

function WhoItsForSection() {
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setActiveSlide((prev) => (prev + 1) % slides.length);
      }
    }, 6000);

    return () => clearInterval(interval);
  }, [isPaused]);

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };

  const handleNavigation = (direction) => {
    if (direction === 'prev') {
      setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
    } else {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }
  };

  return (
    <Box
      component="section"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        height: 'auto',
        overflow: 'hidden',
        py: { xs: 4, sm: 6 }
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 3, sm: 4 }
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography 
            variant="h1" 
            sx={{ 
              fontFamily: '"Love Ya Like A Sister", cursive',
              color: 'primary.main',
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              lineHeight: 1.2,
              mb: 2
            }}
          >
            America's Education Crisis Exposed!
          </Typography>

          <Typography 
            variant="subtitle1"
            sx={{ 
              color: 'text.secondary',
              fontStyle: 'italic',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
              maxWidth: '800px',
              margin: '0 auto'
            }}
          >
            Our students are falling behind in a rapidly changing world. 
            The consequences are dire, and the time to act is now.
          </Typography>
        </Box>

        <Grid 
          container 
          spacing={4} 
          sx={{ 
            width: '100%',
            m: 0,
            '& > .MuiGrid-item': {
              pt: { xs: 2, sm: 3 },
              px: { xs: 2, sm: 3 }
            }
          }}
        >
          <Grid item xs={12} lg={7}>
            <Box sx={{
              bgcolor: 'background.paper',
              borderRadius: theme => theme.shape.borderRadius * 2,
              p: { xs: 2, sm: 3, md: 4 },
              boxShadow: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Typography variant="h4" sx={{ 
                color: 'secondary.main',
                mb: { xs: 2, md: 3 },
                textAlign: 'center',
                fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
              }}>
                {slides[activeSlide].title}
              </Typography>

              <Typography variant="body1" sx={{ 
                color: 'text.secondary',
                mb: { xs: 2, md: 3 },
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }
              }}>
                {slides[activeSlide].description}
              </Typography>

              <Box
                component="img"
                src={slides[activeSlide].image}
                alt={slides[activeSlide].title}
                sx={{
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  mx: 'auto',
                  mt: 'auto'
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={5}>
            <Box
              component="img"
              src={educationSvg}
              alt="Education Crisis Illustration"
              sx={{
                width: '100%',
                maxWidth: '500px',
                height: 'auto',
                display: 'block',
                mx: 'auto'
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default WhoItsForSection;
