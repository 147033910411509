import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  Paper,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Navigation items with their corresponding section IDs
const navItems = [
  { title: 'Introduction', id: 'intro' },
  { title: 'Acceptance of Terms', id: 'acceptance' },
  { title: 'User Accounts', id: 'accounts' },
  { title: 'User Conduct', id: 'conduct' },
  { title: 'Intellectual Property', id: 'ip' },
  { title: 'Content Guidelines', id: 'content' },
  { title: 'Payment Terms', id: 'payment' },
  { title: 'Termination', id: 'termination' },
  { title: 'Disclaimers', id: 'disclaimers' },
  { title: 'Limitation of Liability', id: 'liability' },
  { title: 'Changes to Terms', id: 'changes' },
  { title: 'Contact Us', id: 'contact' },
  { title: 'Data Security', id: 'data-security' },
  { title: 'Accessibility', id: 'accessibility' },
  { title: 'Educational Rights', id: 'educational-rights' },
];

function TermsOfServiceSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('intro');

  // Add intersection observer to update active section while scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: '-50px 0px -50px 0px',
        threshold: 0.2
      }
    );

    // Observe all sections
    navItems.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    return () => {
      navItems.forEach((item) => {
        const element = document.getElementById(item.id);
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  console.log('Theme colors:', theme.palette);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Smooth scroll to section with offset for header
      const offset = 80; // Adjust based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      setActiveSection(sectionId);
      if (isMobile) {
        setMobileOpen(false);
      }
    }
  };

  const SideNav = () => (
    <Box
      sx={{
        width: '100%',
        maxWidth: { md: '280px' },
        bgcolor: 'background.paper',
        p: 3,
      }}
    >
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
        Contents
      </Typography>
      <List sx={{ position: 'sticky', top: 24 }}>
        {navItems.map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() => scrollToSection(item.id)}
            sx={{
              borderRadius: 1,
              mb: 0.5,
              bgcolor: activeSection === item.id ? 'primary.light' : 'transparent',
              color: (theme) => activeSection === item.id 
                ? theme.palette.primary.main 
                : theme.palette.text.disabled,
              '&:hover': {
                bgcolor: 'primary.lighter',
              },
            }}
          >
            <ListItemText 
              primary={item.title}
              primaryTypographyProps={{
                fontSize: '0.95rem',
                fontWeight: activeSection === item.id ? 600 : 400,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: 'background.default',
      pt: { xs: 8, sm: 10 },
      pb: { xs: 6, sm: 8 },
    }}>
      {/* Mobile menu button */}
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ 
            position: 'fixed',
            left: 16,
            top: 16,
            zIndex: 1200,
            bgcolor: 'background.paper',
            boxShadow: 2,
            '&:hover': { bgcolor: 'background.paper' },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Mobile drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '280px',
            boxSizing: 'border-box',
          },
        }}
      >
        <SideNav />
      </Drawer>

      <Container maxWidth="xl">
        <Box sx={{ 
          display: 'flex',
          gap: { md: 6, lg: 8 },
        }}>
          {/* Desktop side navigation */}
          <Box sx={{ 
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
          }}>
            <Paper
              elevation={2}
              sx={{
                position: 'sticky',
                top: 24,
                width: '280px',
                borderRadius: 2,
              }}
            >
              <SideNav />
            </Paper>
          </Box>

          {/* Main content */}
          <Box sx={{ flex: 1 }}>
            <Paper
              elevation={2}
              sx={{
                p: { xs: 3, sm: 4, md: 5 },
                borderRadius: 2,
              }}
            >
              <section id="intro">
                <Typography variant="h4" gutterBottom>
                  Terms of Service
                </Typography>
                <Typography variant="body1" paragraph>
                  Last updated: January 20, 2025
                </Typography>
                <Typography variant="body1" paragraph>
                  Welcome to MyDailyMath. These Terms of Service ("Terms") govern your access to and use of MyDailyMath's website, services, and educational content (collectively, the "Services") provided by ALFEROV EDUCATIONAL INSTITUTE ("we," "us," or "our"), a registered 501(c)(3) non-profit organization.
                </Typography>
              </section>

              <section id="acceptance">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                  By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you are using our Services on behalf of an organization or entity ("Organization"), you represent and warrant that you have the authority to bind that Organization to these Terms.
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  1.1 Age Requirements
                </Typography>
                <Typography variant="body1" paragraph>
                  Our Services are intended for:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Users 13 years or older who can form a binding contract</li>
                  <li>Users under 13 with verifiable parental consent</li>
                  <li>Educational institutions with proper authorization</li>
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  1.2 Changes to Terms
                </Typography>
                <Typography variant="body1" paragraph>
                  We may modify these Terms at any time. We will notify you of material changes by:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Posting the updated Terms on our website</li>
                  <li>Sending an email to registered users</li>
                  <li>Providing notice through our Services</li>
                </Typography>
              </section>

              <section id="accounts">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  2. User Accounts
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  2.1 Account Creation
                </Typography>
                <Typography variant="body1" paragraph>
                  To access certain features, you may need to create an account. You agree to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Provide accurate and complete information</li>
                  <li>Maintain the security of your account credentials</li>
                  <li>Promptly update any changes to your information</li>
                  <li>Accept responsibility for all activities under your account</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  2.2 Account Types
                </Typography>
                <Typography variant="body1" paragraph>
                  We offer different types of accounts:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Student Accounts: For individual learners</li>
                  <li>Parent Accounts: For monitoring child users</li>
                  <li>Educator Accounts: For teachers and instructors</li>
                  <li>Institution Accounts: For schools and organizations</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  2.3 Account Termination
                </Typography>
                <Typography variant="body1" paragraph>
                  We reserve the right to suspend or terminate accounts that:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Violate these Terms or our policies</li>
                  <li>Engage in fraudulent or harmful activity</li>
                  <li>Remain inactive for extended periods</li>
                  <li>Are required to be removed by law</li>
                </Typography>
              </section>

              <section id="conduct">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  3. User Conduct
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  3.1 Acceptable Use
                </Typography>
                <Typography variant="body1" paragraph>
                  You agree to use our Services only for:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Educational and learning purposes</li>
                  <li>Lawful and authorized activities</li>
                  <li>Personal or institutional use as intended</li>
                  <li>Constructive community participation</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  3.2 Prohibited Activities
                </Typography>
                <Typography variant="body1" paragraph>
                  You must not:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Share account credentials or access</li>
                  <li>Violate intellectual property rights</li>
                  <li>Upload harmful or malicious content</li>
                  <li>Attempt to breach security measures</li>
                  <li>Harass or harm other users</li>
                  <li>Use the Services for commercial purposes</li>
                  <li>Interfere with the proper functioning of the Services</li>
                </Typography>
              </section>

              <section id="ip">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  4. Intellectual Property Rights
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  4.1 Our Intellectual Property
                </Typography>
                <Typography variant="body1" paragraph>
                  All content and materials available through our Services are protected by:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Copyright laws</li>
                  <li>Trademark rights</li>
                  <li>Other proprietary rights</li>
                  <li>Intellectual property licenses</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  4.2 Limited License
                </Typography>
                <Typography variant="body1" paragraph>
                  We grant you a limited, non-exclusive, non-transferable license to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Access and view educational content for personal use</li>
                  <li>Download materials specifically marked for download</li>
                  <li>Use interactive features as intended</li>
                  <li>Print reasonable portions for personal educational use</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  4.3 Restrictions
                </Typography>
                <Typography variant="body1" paragraph>
                  You may not:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Modify, copy, or create derivative works</li>
                  <li>Remove any copyright or proprietary notices</li>
                  <li>Use content for commercial purposes</li>
                  <li>Share access credentials or materials with unauthorized users</li>
                  <li>Attempt to decompile or reverse engineer any software</li>
                </Typography>
              </section>

              <section id="content">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  5. Content Guidelines
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  5.1 User-Generated Content
                </Typography>
                <Typography variant="body1" paragraph>
                  When submitting content to our Services, you:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Retain ownership of your original content</li>
                  <li>Grant us a worldwide, non-exclusive license to use your content</li>
                  <li>Warrant that you have necessary rights to share the content</li>
                  <li>Accept responsibility for content accuracy and appropriateness</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  5.2 Content Standards
                </Typography>
                <Typography variant="body1" paragraph>
                  All content must be:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Educational and constructive in nature</li>
                  <li>Accurate and factual</li>
                  <li>Free from discriminatory or harmful material</li>
                  <li>Respectful of intellectual property rights</li>
                  <li>Appropriate for all age groups</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  5.3 Content Moderation
                </Typography>
                <Typography variant="body1" paragraph>
                  We reserve the right to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Review and moderate all user-generated content</li>
                  <li>Remove content that violates our guidelines</li>
                  <li>Suspend accounts that repeatedly violate guidelines</li>
                  <li>Modify or adapt content for technical compatibility</li>
                </Typography>
              </section>

              <section id="payment">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  6. Donation and Payment Terms
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  6.1 Donations
                </Typography>
                <Typography variant="body1" paragraph>
                  As a 501(c)(3) non-profit organization:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>All donations are tax-deductible as permitted by law</li>
                  <li>Donations are processed through secure payment providers</li>
                  <li>Tax receipts are provided for donations over $250</li>
                  <li>Recurring donations can be modified or cancelled at any time</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  6.2 Payment Processing
                </Typography>
                <Typography variant="body1" paragraph>
                  For any payments or donations:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>We use industry-standard payment processors</li>
                  <li>All transactions are encrypted and secure</li>
                  <li>We never store complete credit card information</li>
                  <li>Refunds are handled according to our refund policy</li>
                </Typography>
              </section>

              <section id="disclaimers">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  7. Disclaimers and Warranties
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  7.1 Service Availability
                </Typography>
                <Typography variant="body1" paragraph>
                  While we strive to provide continuous access to our Services:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Services are provided "as is" and "as available"</li>
                  <li>We do not guarantee uninterrupted access</li>
                  <li>We may perform maintenance or updates that affect availability</li>
                  <li>Technical issues may occasionally impact service delivery</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  7.2 Educational Content
                </Typography>
                <Typography variant="body1" paragraph>
                  Regarding our educational materials:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Content is for educational purposes only</li>
                  <li>We strive for accuracy but cannot guarantee it</li>
                  <li>Materials may be updated or modified over time</li>
                  <li>Content may not be suitable for all skill levels or needs</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  7.3 Third-Party Content
                </Typography>
                <Typography variant="body1" paragraph>
                  For third-party content and links:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>We are not responsible for external content</li>
                  <li>Third-party terms may apply to their services</li>
                  <li>We do not endorse linked external content</li>
                  <li>Users access third-party content at their own risk</li>
                </Typography>
              </section>

              <section id="liability">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  8. Limitation of Liability
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  8.1 Liability Limits
                </Typography>
                <Typography variant="body1" paragraph>
                  To the maximum extent permitted by law, we shall not be liable for:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Direct, indirect, or consequential damages</li>
                  <li>Loss of data or educational progress</li>
                  <li>Service interruptions or technical issues</li>
                  <li>Actions of other users or third parties</li>
                  <li>Content accuracy or suitability</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  8.2 Indemnification
                </Typography>
                <Typography variant="body1" paragraph>
                  You agree to indemnify and hold us harmless from:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Claims arising from your use of the Services</li>
                  <li>Violations of these Terms</li>
                  <li>Infringement of third-party rights</li>
                  <li>User-generated content you share</li>
                </Typography>
              </section>

              <section id="termination">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  9. Term and Termination
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  9.1 Duration
                </Typography>
                <Typography variant="body1" paragraph>
                  These Terms remain in effect until:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>You cease using our Services</li>
                  <li>Your account is terminated</li>
                  <li>We discontinue the Services</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  9.2 Effects of Termination
                </Typography>
                <Typography variant="body1" paragraph>
                  Upon termination:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Access to Services will cease</li>
                  <li>Educational progress may be archived</li>
                  <li>Certain Terms will survive termination</li>
                  <li>User data will be handled per our Privacy Policy</li>
                </Typography>
              </section>

              <section id="disputes">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  10. Dispute Resolution
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  10.1 Governing Law and Jurisdiction
                </Typography>
                <Typography variant="body1" paragraph>
                  These Terms are governed by and construed in accordance with:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Laws of the State of California, without regard to its conflict of law principles</li>
                  <li>Federal laws of the United States, including FERPA and COPPA</li>
                  <li>Exclusive jurisdiction of the courts located in Santa Clara County, California</li>
                  <li>Applicable international education and privacy regulations</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  10.2 Arbitration Agreement
                </Typography>
                <Typography variant="body1" paragraph>
                  Any dispute arising from these Terms shall be resolved through binding arbitration:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Administered by the American Arbitration Association (AAA)</li>
                  <li>Conducted under AAA's Educational Institution Arbitration Rules</li>
                  <li>Held in Santa Clara County, California</li>
                  <li>Conducted in English language</li>
                  <li>Decision will be final and binding</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  10.3 Time Limitations
                </Typography>
                <Typography variant="body1" paragraph>
                  Claims must be filed within the following timeframes:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Within one (1) year of the incident giving rise to the claim</li>
                  <li>Within thirty (30) days for billing disputes</li>
                  <li>Within ninety (90) days for content-related claims</li>
                  <li>As required by applicable law for statutory claims</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  10.4 Available Remedies
                </Typography>
                <Typography variant="body1" paragraph>
                  Potential remedies may include:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Monetary damages limited to actual losses</li>
                  <li>Injunctive or declaratory relief</li>
                  <li>Account restoration or content recovery</li>
                  <li>Specific performance of Services</li>
                </Typography>
              </section>

              <section id="international">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  15. International Usage and Export Controls
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  15.1 International Access
                </Typography>
                <Typography variant="body1" paragraph>
                  For users accessing our Services internationally:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Services are primarily intended for U.S. users</li>
                  <li>Content may not be appropriate or available in all regions</li>
                  <li>Local laws and regulations may restrict access</li>
                  <li>Language support may be limited</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  15.2 Export Controls
                </Typography>
                <Typography variant="body1" paragraph>
                  Users must comply with U.S. export controls:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>No access from prohibited countries or regions</li>
                  <li>No transfer of technical data without proper authorization</li>
                  <li>Compliance with U.S. trade restrictions</li>
                  <li>User responsibility for export law compliance</li>
                </Typography>
              </section>

              <section id="limitations">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  16. Service Limitations
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  16.1 Emergency Services
                </Typography>
                <Typography variant="body1" paragraph>
                  Important limitations of our Services:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Not intended for emergency communications</li>
                  <li>No real-time monitoring or alert systems</li>
                  <li>Not a substitute for professional medical or emergency services</li>
                  <li>Users should contact appropriate authorities for emergencies</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  16.2 Force Majeure
                </Typography>
                <Typography variant="body1" paragraph>
                  We are not liable for failures or delays due to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Natural disasters or acts of God</li>
                  <li>War, terrorism, or civil unrest</li>
                  <li>Government actions or regulations</li>
                  <li>Network or power failures</li>
                  <li>Pandemic or public health emergencies</li>
                  <li>Other circumstances beyond our reasonable control</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  16.3 Technical Limitations
                </Typography>
                <Typography variant="body1" paragraph>
                  Service availability may be affected by:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Scheduled maintenance windows</li>
                  <li>Hardware or software updates</li>
                  <li>Third-party service interruptions</li>
                  <li>Internet connectivity issues</li>
                  <li>Security incidents or threats</li>
                </Typography>
              </section>

              <section id="contact">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  11. Contact Information
                </Typography>
                
                <Typography variant="body1" paragraph>
                  For questions about these Terms:
                </Typography>
                <Typography variant="body1">
                  ALFEROV EDUCATIONAL INSTITUTE
                </Typography>
                <Typography variant="body1">
                  13525 INDIAN TRAIL ROAD
                </Typography>
                <Typography variant="body1">
                  LOS GATOS, CA 95033
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Email: info@mydailymath.com
                </Typography>
              </section>

              <section id="data-security">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  12. Data Security and Protection
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  12.1 Security Measures
                </Typography>
                <Typography variant="body1" paragraph>
                  We implement industry-standard security measures including:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>End-to-end encryption for data transmission</li>
                  <li>Regular security audits and penetration testing</li>
                  <li>Multi-factor authentication options</li>
                  <li>Secure data backup and recovery procedures</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  12.2 Data Breach Procedures
                </Typography>
                <Typography variant="body1" paragraph>
                  In the event of a data breach:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Immediate notification to affected users</li>
                  <li>Cooperation with law enforcement if necessary</li>
                  <li>Implementation of remedial measures</li>
                  <li>Post-incident security review and updates</li>
                </Typography>
              </section>

              <section id="accessibility">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  13. Accessibility Commitment
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  13.1 Standards Compliance
                </Typography>
                <Typography variant="body1" paragraph>
                  We are committed to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>WCAG 2.1 Level AA compliance</li>
                  <li>Regular accessibility audits</li>
                  <li>Alternative formats for educational content</li>
                  <li>Assistive technology compatibility</li>
                </Typography>
              </section>

              <section id="educational-rights">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  14. Educational Rights and Responsibilities
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  14.1 Student Rights
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Right to equal access to educational content</li>
                  <li>Right to privacy of educational records</li>
                  <li>Right to reasonable accommodations</li>
                  <li>Right to fair assessment and evaluation</li>
                </Typography>
              </section>

              <section id="definitions">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  17. Definitions and Interpretations
                </Typography>
                
                <Typography variant="body1" paragraph>
                  Throughout these Terms, unless otherwise specified:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>"Services" refers to MyDailyMath platform, content, and related services</li>
                  <li>"User" means any person accessing or using the Services</li>
                  <li>"Educational Content" means all learning materials, exercises, and assessments</li>
                  <li>"Account" refers to registered user profiles and associated data</li>
                </Typography>
              </section>

              <section id="severability">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  18. Severability and Survival
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  18.1 Severability
                </Typography>
                <Typography variant="body1" paragraph>
                  If any provision of these Terms is found to be unenforceable:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>The provision will be modified to the minimum extent necessary</li>
                  <li>Remaining provisions will continue in full effect</li>
                  <li>Invalid provisions will be interpreted to achieve original intent</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  18.2 Survival
                </Typography>
                <Typography variant="body1" paragraph>
                  The following sections survive termination:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Intellectual Property Rights</li>
                  <li>Privacy and Data Protection</li>
                  <li>Limitation of Liability</li>
                  <li>Dispute Resolution</li>
                </Typography>
              </section>

              <section id="updates">
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                  19. Updates and Modifications
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  19.1 Terms Updates
                </Typography>
                <Typography variant="body1" paragraph>
                  Process for updating these Terms:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>30 days advance notice for material changes</li>
                  <li>Email notification to registered users</li>
                  <li>Prominent notice on our website</li>
                  <li>Right to reject changes by discontinuing use</li>
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 600 }}>
                  19.2 Service Modifications
                </Typography>
                <Typography variant="body1" paragraph>
                  We reserve the right to:
                </Typography>
                <Typography component="ul" sx={{ pl: 4 }}>
                  <li>Modify or discontinue features</li>
                  <li>Update educational content</li>
                  <li>Change technical requirements</li>
                  <li>Adjust service availability</li>
                </Typography>
              </section>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default TermsOfServiceSection;
