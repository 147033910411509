import PrivacyPolicySection from "../components/PrivacyPolicySection"


function PrivacyPolicyPage() {
    return (
        <PrivacyPolicySection />
    )
}

export default PrivacyPolicyPage;
