import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Container, Grid, Paper, TextField, MenuItem, Fade, Chip, Zoom, Avatar, Snackbar, Alert, CircularProgress, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { CenterFocusStrong, TrendingUp, TrackChanges, School, Person } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DonationForm from './DonationForm';

const formatNumber = (num) => {
  return `${(num / 1000).toFixed(0)}K+`;
};

function HeroSection() {
  const theme = useTheme();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [studentCount, setStudentCount] = useState(25600);
  const [teacherCount, setTeacherCount] = useState(1600);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${API_URL}/form/waiting-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          grade: grade || undefined
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.errors?.[0]?.msg || data.message || 'Failed to submit form');
      }

      // Success handling
      setIsSubmitted(true);
      setSnackbarSeverity('success');
      setSnackbarMessage('Thank you for joining! Welcome to MyDailyMath community! 🎉');
      setOpenSnackbar(true);
      
      // Reset form
      setName('');
      setEmail('');
      setGrade('');

    } catch (error) {
      setError(error.message);
      setSnackbarSeverity('error');
      setSnackbarMessage(error.message);
      setOpenSnackbar(true);
      console.error('Form submission error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const features = [
    { label: 'Focused Practice', icon: <CenterFocusStrong /> },
    { label: 'Adaptive Learning', icon: <TrendingUp /> },
    { label: 'Progress Tracking', icon: <TrackChanges /> },
    { label: 'Proven Curriculum', icon: <School /> },
  ];

  const testimonials = [
    { name: "Sarah J.", role: "5th Grade Teacher", state: "CA", text: "MyDailyMath has transformed how I teach math. My students are more engaged than ever!" },
    { name: "Mike R.", role: "Parent", state: "NY", grade: "7th", text: "I've seen a significant improvement in my daughter's math skills. Highly recommended!" },
    { name: "Emily L.", role: "Middle School Principal", state: "TX", text: "The adaptive learning feature ensures each student is appropriately challenged. It's a game-changer!" },
  ];

  const updateTestimonial = useCallback(() => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  }, [testimonials.length]);

  const updateCounts = useCallback(() => {
    setStudentCount((prev) => prev + Math.floor(Math.random() * 3));
    setTeacherCount((prev) => prev + Math.floor(Math.random() * 2));
  }, []);

  useEffect(() => {
    const testimonialTimer = setInterval(updateTestimonial, 5000);
    const countTimer = setInterval(updateCounts, 5000);

    return () => {
      clearInterval(testimonialTimer);
      clearInterval(countTimer);
    };
  }, [updateTestimonial, updateCounts]);

  return (
    <Box
      component="section"
      sx={{
        width: '100%',
        minHeight: '100dvh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(135deg, #003B5C 0%, #001F3F 100%)',
        overflow: 'hidden',
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 6, sm: 8, md: 10 },

        // Radial glow effect
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          background: 'radial-gradient(circle at center, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 70%)',
          opacity: 0.8,
          pointerEvents: 'none',
          zIndex: 1,
        },

        // Topographic lines effect
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `
            linear-gradient(
              -45deg,
              rgba(255,255,255,0.08) 0%,
              rgba(255,255,255,0.08) 1px,
              transparent 1px,
              transparent 40px
            ),
            linear-gradient(
              45deg,
              rgba(255,255,255,0.08) 0%,
              rgba(255,255,255,0.08) 1px,
              transparent 1px,
              transparent 40px
            )
          `,
          backgroundSize: '40px 40px',
          opacity: 1,
          pointerEvents: 'none',
          zIndex: 1,
        }
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          height: '100%',
          maxWidth: '100%',
          zIndex: 2,
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to top, rgba(0,59,92,0.95) 0%, rgba(0,59,92,0.5) 50%, rgba(0,59,92,0.3) 100%)',
            pointerEvents: 'none',
          }
        }}
      >
        <Box
          component="img"
          src="/education-teachers-university-schools-concept 1.png"
          alt=""
          role="presentation"
          loading="eager"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'bottom center',
            filter: 'brightness(0.9)',
          }}
        />
      </Box>

      {/* Main Content Container */}
      <Container 
        maxWidth="xl" 
        
      >
        <Grid 
          container 
          spacing={{ xs: 2, sm: 4, md: 6 }} 
          sx={{ 
            height: '100%',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          {/* Left Content Column */}
          <Grid 
            item 
            xs={12} 
            lg={6} 
            sx={{
              mt: { xs: 8, sm: 12, md: 16, lg: 20 },
              position: 'relative',
              zIndex: 2,
              order: { xs: 1, lg: 1 },  // Changed order for mobile
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ px: { xs: 2, sm: 4 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem', lg: '5.5rem' },
                  color: 'white',
                  mb: { xs: 2, sm: 3 },
                  lineHeight: 1.2,
                  fontWeight: 700
                }}
              >
                MyDailyMath
              </Typography>

              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.2rem', lg: '2.8rem' },
                  color: 'rgba(255,255,255,0.9)',
                  mb: { xs: 3, sm: 4, md: 6 },
                  lineHeight: 1.3,
                  maxWidth: '90%'
                }}
              >
                Empowering students with adaptive, daily math practice
              </Typography>

              {/* Features Grid */}
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                gap: { xs: 2, sm: 3 },
                mb: { xs: 4, sm: 6 },
                width: '100%',
                maxWidth: { sm: '540px' }
              }}>
                {features.map((feature) => (
                  <motion.div
                    key={feature.label}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Button
                      startIcon={
                        <Box sx={{ 
                          '& > svg': { 
                            color: 'accent_one.main',
                            fontSize: { xs: '1.5rem', sm: '1.75rem' }
                          }
                        }}>
                          {feature.icon}
                        </Box>
                      }
                      sx={{
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: '16px',
                        px: { xs: 2, sm: 3 },
                        py: 1.5,
                        width: '100%',
                        fontSize: { xs: '0.9rem', sm: '1.1rem' },
                        fontWeight: 300,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        },
                      }}
                    >
                      {feature.label}
                    </Button>
                  </motion.div>
                ))}
              </Box>

              {/* Stats Cards */}
              <Box sx={{ 
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 3,
                mb: { xs: 2, sm: 4, md: 6 }
              }}>
                <StatsCard
                  icon={<School />}
                  count={teacherCount}
                  label="Teachers"
                />
                <StatsCard
                  icon={<Person />}
                  count={studentCount}
                  label="Students"
                />
              </Box>

              {/* Form Container - Only visible on mobile/tablet */}
              <Box 
                sx={{
                  display: { xs: 'block', lg: 'none' },
                  width: '100%',
                  maxWidth: '450px',
                  mx: 'auto',
                  mt: { xs: 4, sm: 6 }
                }}
              >
                <DonationForm />
              </Box>
            </Box>
          </Grid>

          {/* Right Column - Image and Desktop Form */}
          <Grid 
            item 
            xs={12} 
            lg={6} 
            sx={{
              mt: 46,
              position: 'relative',
              order: { xs: 2, lg: 2 },
              display: { xs: 'none', lg: 'block' }  // Only show on desktop
            }}
          >
            {/* Desktop Form Container */}
            <Box sx={{
              maxWidth: '450px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              zIndex: 3
            }}>
              <DonationForm />
            </Box>

            {/* Background Image */}
            <Box
              sx={{
                position: 'absolute',
                right: '-15%',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '115%',
                height: '100%',
                zIndex: 1,
                '&::after': {  // Image overlay gradient
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(90deg, rgba(0,59,92,0.8) 0%, rgba(0,59,92,0) 100%)',
                  pointerEvents: 'none',
                }
              }}
            >
              <Box
                component="img"
                src="/education-teachers-university-schools-concept 1.png"
                alt="Teacher"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'bottom center'
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          icon={snackbarSeverity === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
          sx={{
            width: '100%',
            maxWidth: '500px',
            alignItems: 'center',
            '& .MuiAlert-icon': {
              fontSize: '2rem',
              marginRight: 2,
            },
            '& .MuiAlert-message': {
              fontSize: '1rem',
              fontWeight: 500,
            },
            boxShadow: (theme) => theme.shadows[3],
            borderRadius: '12px',
            ...(snackbarSeverity === 'success' && {
              backgroundImage: (theme) => `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.dark})`,
            }),
            ...(snackbarSeverity === 'error' && {
              backgroundImage: (theme) => `linear-gradient(135deg, ${theme.palette.error.main}, ${theme.palette.error.dark})`,
            }),
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

// Stats Card Component
const StatsCard = ({ icon, count, label }) => (
  <Paper
    elevation={4}
    sx={{
      p: { xs: 2, sm: 3 },
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      flex: 1,
      backgroundColor: 'white'
    }}
  >
    <Box sx={{ color: 'primary.main', '& > svg': { fontSize: '2.5rem' } }}>
      {icon}
    </Box>
    <Box>
      <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.5rem' }}>
        {formatNumber(count)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </Box>
  </Paper>
);

export default HeroSection;