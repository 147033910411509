import React from 'react';
import { Box, Container, Typography, Grid, useTheme } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import DonationForm from './DonationForm';

function CTASection() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        bgcolor: theme.palette.background.default,
        py: { xs: 6, md: 10 },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} justifyContent="center" alignItems="center">
          {/* Left side - Value proposition */}
          <Grid item xs={12} lg={6}>
            <Typography variant="h2" gutterBottom sx={{ 
              fontWeight: 'bold', 
              mb: 3, 
              color: 'primary.main',
              fontSize: { xs: '2.5rem', sm: '3rem', lg: '3.5rem' },
              lineHeight: 1.2
            }}>
              Support Math Education
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ 
              fontWeight: 'medium', 
              mb: 4, 
              color: 'text.secondary',
              fontSize: { xs: '1.25rem', sm: '1.35rem', lg: '1.5rem' },
              lineHeight: 1.6
            }}>
              Help us make quality math education accessible to everyone
            </Typography>

            {/* Testimonial Quote */}
            <Box sx={{ 
              bgcolor: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
              p: { xs: 3, sm: 4 },
              position: 'relative',
              boxShadow: theme.shadows[2],
              mt: 6,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 3, sm: 4 },
              alignItems: { xs: 'center', sm: 'flex-start' }
            }}>
              {/* Large Image */}
              <Box
                component="img"
                src="person_testimonial.png"  // Update with your image path
                alt="Student testimonial"
                sx={{
                  width: { xs: 120, sm: 140 },
                  height: { xs: 120, sm: 140 },
                  borderRadius: '50%',
                  objectFit: 'cover',
                  flexShrink: 0,
                }}
              />
              
              {/* Quote Content */}
              <Box sx={{ position: 'relative', textAlign: { xs: 'center', sm: 'left' } }}>
                {/* Large Quote Mark */}
                <Typography
                  sx={{
                    position: 'absolute',
                    top: -40,
                    mt: 2,
                    left: { xs: '50%', sm: -10 },
                    transform: { xs: 'translateX(-50%)', sm: 'none' },
                    fontSize: { xs: '100px', sm: '120px' },
                    fontFamily: 'Georgia, serif',
                    color: theme.palette.grey[600],
                    lineHeight: 1,
                    userSelect: 'none',
                  }}
                >
                  "
                </Typography>
                
                <Typography variant="body1" sx={{ 
                  fontStyle: 'italic', 
                  mb: 2,
                  left: -10,
                  mt: 4,
                  fontSize: '120px',
                  color: 'text.primary',
                  fontSize: { xs: '1.1rem', sm: '1.25rem' },
                  lineHeight: 1.6,
                  position: 'relative',
                  zIndex: 1,
                }}>
                  "This project has completely changed how I approach math. It's like having a personal tutor available 24/7!"
                </Typography>
                
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: { xs: 'center', sm: 'flex-start' }
                }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                    Jane Smith
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    10th Grade Student
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right side - Donation Form */}
          <Grid item xs={12} lg={6}>
            <DonationForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CTASection;