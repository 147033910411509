import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CanceledPage() {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center', py: 8 }}>
      <Typography variant="h4" gutterBottom>
        Donation Canceled
      </Typography>
      <Typography variant="body1" paragraph>
        Your donation was canceled. No charges were made.
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => navigate('/')}
      >
        Try Again
      </Button>
    </Box>
  );
}

export default CanceledPage; 